@font-face {
    font-family: "Confortaa-Regular";
    src: url("./Comfortaa-Regular.ttf") format('truetype');
}
@font-face {
    font-family: "Confortaa-Light";
    src: url("./Comfortaa-Light.ttf") format('truetype');
}
@font-face {
    font-family: "Confortaa-Bold";
    src: url("./Comfortaa-Bold.ttf") format('truetype');
}

@font-face {
    font-family: "Poppins-Regular";
    src: url("./Poppins-Regular.ttf") format('truetype');
}

@font-face {
    font-family: "Poppins-Bold";
    src: url("./Poppins-Bold.ttf") format('truetype');
}
@font-face {
    font-family: "Poppins-Light";
    src: url("./Poppins-Light.ttf") format('truetype');
}
@font-face {
    font-family: "Poppins-Medium";
    src: url("./Poppins-Medium.ttf") format('truetype');
}
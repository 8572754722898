.customIcon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-size: cover;
  cursor: pointer;
}

.uiDisabledIcon {
  opacity: 0.2;
  pointer-events: none;
}

.uiLogoutIcon {
  margin-left: 1.5rem;
  background-image: url("./logout.svg");
}

.uiMenuFoldIcon {
  margin-right: 1rem;
  background-image: url("./menu-fold.svg");
}

.uiDesignerIcon {
  margin-right: 10px;
  background-image: url("./design.svg");
}

.uiSimulazioneIcon {
  margin-right: 10px;
  background-image: url("./simulazione.svg");
}

.uiUtentigruppiIcon {
  margin-right: 10px;
  background-image: url("./utenti-gruppi.svg");
}

.uiClientiaziendeIcon {
  margin-right: 10px;
  background-image: url("./clienti-aziende.svg");
}

.uiPubblicazioneIcon {
  margin-right: 10px;
  background-image: url("./pubblicazione.svg");
}

.uiQuestionariIcon {
  margin-right: 10px;
  background-image: url("./questionari.svg");
}

.uiAzienda{
  margin-right: 10px;
  background-image: url("./utenti-gruppi-hover.svg");
}



.uiDestinatariIcon {
  margin-right: 10px;
  background-image: url("./destinatari.svg");
}

.uiQuestIcon {
  margin-right: 10px;
  background-image: url("./quest.svg");
}

.uiAggregatiIcon {
  margin-right: 10px;
  background-image: url("./aggregati.svg");
}

.uiValutazioniIcon {
  background-image: url("./valutazioni.svg");
}

.uiValutazioniRedIcon {
  margin-right: 10px;
  background-image: url("./valutazioni-hover.svg");
}

.uiWhiteValutazioniIcon {
  margin-right: 10px;
  background-image: url("./valutazioni-white.svg");
}

.uiGreyValutazioniIcon {
  background-image: url("./valutazioni-grey.svg");
  height: 25px;
  width: 25px;
}

.uiPrivacyIcon {
  margin-right: 10px;
  background-image: url("./privacy.svg");
}

.uiPlusCircleIcon {
  background-image: url("./plus-circle.svg");
}

.uiPlusCircleIcon:hover {
  background-image: url("./plus-circle-hover.svg");
}

.uiFilterIcon {
  background-image: url("./filter-search.svg");
  margin-right: 5px;
}

.uiAddFilterIcon {
  background-image: url("./filter.svg");
}

.uiAddFilterIcon:hover {
  background-image: url("./filter.svg");
}

.uiFilterRoundIcon {
  background-image: url("./filter-round.svg");
}

.uiTrashIcon {
  background-image: url("./trash.svg");
}

.uiAddIcon {
  background-image: url("./plus-grey.svg");
}

.uiExcelIcon {
  width: 17px;
  height: 17px;
  margin-bottom: 1px;
  background-image: url("./excel.svg");
}

.uiExcelIconWhite {
  width: 17px;
  height: 17px;
  margin-bottom: 1px;
  background-image: url("./excel-white.svg");
}

.uiCloseIcon {
  background-image: url("./close.svg");
}

.uiCloseGreyIcon {
  background-image: url("./close-grey.svg");
}

.uiFilterGreyIcon {
  background-image: url("./filter-grey.svg");
}

.uiCopyIcon {
  background-image: url("./copy.svg");
}

.uiHeadIcon {
  margin-right: 5px;
  background-image: url("./head.svg");
}

.uiQuestionsIcon {
  margin-right: 5px;
  background-image: url("./questions.svg");
}

.uiConnectIcon {
  margin-right: 5px;
  background-image: url("./connect.svg");
}

.uiShieldIcon {
  margin-right: 5px;
  background-image: url("./shield.svg");
}

.uiLightningIcon {
  margin-right: 5px;
  background-image: url("./lightning.svg");
}

.uiRollbackIcon {
  margin-right: 5px;
  background-image: url("./rollback.svg");
}

.uiSaveIcon {
  margin-right: 5px;
  background-image: url("./save.svg");
}

.uiSaveRedIcon {
  margin-right: 5px;
  background-image: url("./save-red.svg");
}

.uiSaveGreyIcon {
  margin-right: 5px;
  background-image: url("./save-grey.svg");
}

.uiListIcon {
  background-image: url("./list.svg");
}

.uiSurveyIcon {
  background-image: url("./survey.svg");
}

.uiSurveyGreyIcon {
  background-image: url("./survey-grey.svg");
}

.uiConnectGreyIcon {
  background-image: url("./connect-grey.svg");
}

.uiMoreIcon {
  background-image: url("./more.svg");
}

.uiEditIcon {
  background-image: url("./edit.svg");
}

.uiFolderIcon {
  margin-right: 15px;
  background-image: url("./folder.svg");
}

.uiFolderOpenIcon {
  margin-right: 15px;
  background-image: url("./folder-open.svg");
}

.uiTrashGreyIcon {
  margin-right: 15px;
  background-image: url("./trash-grey.svg");
}

.uiTrashGreyIconDisabled {
  opacity: 0.5;
  pointer-events: none;
}

.uiCopyGreyIcon {
  margin-right: 15px;
  background-image: url("./copy-grey.svg");
}

.uiPdfIcon {
  background-image: url("./pdf.svg");
  width: 25px;
  height: 25px;
}

.uiUserIcon {
  margin-right: 15px;
  background-image: url("./user.svg");
}

.uiUserWhiteIcon {
  background-image: url("./user-white.svg");
}

.uiSuperUserIcon {
  margin-right: 15px;
  background-image: url("./superuser.svg");
}

.uiResetIcon {
  background-image: url("./reset.svg");
  height: 25px;
  width: 25px;
}

.uiGreyDesignerIcon {
  background-image: url("./designer-grey.svg");
  height: 25px;
  width: 25px;
}

.uiGreySimulazioneIcon {
  background-image: url("./simulazione-grey.svg");
  height: 25px;
  width: 25px;
}

.uiGreyPubblicazioneIcon {
  background-image: url("./pubblicazione-grey.svg");
  height: 25px;
  width: 25px;
}

.uiGreyUtentigruppiIcon {
  background-image: url("./utenti-gruppi-grey.svg");
  height: 25px;
  width: 25px;
}

.uiGreyClientiaziendeIcon {
  background-image: url("./clienti-aziende-grey.svg");
  height: 25px;
  width: 25px;
}

.uiGreyGestionequestIcon {
  background-image: url("./questionari-grey.svg");
  height: 25px;
  width: 25px;
}

.uiGreyGestionedestIcon {
  background-image: url("./destinatari-grey.svg");
  height: 25px;
  width: 25px;
}

.uiGreyGestioneaggrIcon {
  background-image: url("./aggregati-grey.svg");
  height: 25px;
  width: 25px;
}

.uiGreyPrivacyIcon {
  background-image: url("./privacy-grey.svg");
  height: 25px;
  width: 25px;
}

.uiSelectIcon {
  background-image: url("./select.svg");
}

.uiShowImageIcon {
  background-image: url("./image-show.svg");
  width: 25px;
  height: 25px;
}

.uiAddImageIcon {
  background-image: url("./image-add.svg");
  width: 25px;
  height: 25px;
}

.uiAddImageIconWhite {
  background-image: url("./image-add-white.svg");
  width: 25px;
  height: 25px;
}

.uiLicenseQuestIcon {
  background-image: url("./license-quest.svg");
  width: 20px;
  height: 20px;
}

.uiEmailSend {
  background-image: url("./email-send.svg");
  width: 20px;
  height: 20px;
}

.uiEmailSendWhite {
  background-image: url("./email-send-w.svg");
  width: 20px;
  height: 20px;
}

.uiSmsSend {
  background-image: url("./sms-send.svg");
  width: 20px;
  height: 20px;
}

.uiSmsSendWhite {
  background-image: url("./sms-send-w.svg");
  width: 20px;
  height: 20px;
  &:hover{
    color: red
  }
}
.uiLinkNotSent {
  background-image: url("./link-not-sent.svg");
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.uiLinkSent {
  background-image: url("./link-sent.svg");
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.uiLinkBegin {
  background-image: url("./link-begin.svg");
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.uiLinkEnd {
  background-image: url("./link-end.svg");
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.uiLinkError {
  background-image: url("./link-error.svg");
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.uiReportIcon {
  background-image: url("./report.svg");
  width: 20px;
  height: 20px;
}

.uiReportGrayIcon {
  background-image: url("./report-gray.svg");
  width: 20px;
  height: 20px;
}

.uiAddSimulationIcon {
  background-image: url("./add-simulation.svg");
  width: 20px;
  height: 20px;
}

.uiCommentIcon {
  background-image: url("./comment.svg");
  width: 16px;
  height: 16px;
}

.uiRedCommentIcon {
  background-image: url("./comment-red.svg");
  width: 16px;
  height: 16px;
  margin-top: 4px;
}

.uiWhiteCommentIcon {
  background-image: url("./comment-white.svg");
  width: 16px;
  height: 16px;
  margin-top: 4px;
}

.uiDragIcon {
  background-image: url("./drag.svg");
  width: 20px;
  height: 20px;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.uiLoadingIcon {
  background-image: url("./loading.svg");
  width: 40px;
  height: 40px;
  animation-duration: 1.7s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: spin;
}

.uiFlexCenter {
  display: flex;
  justify-content: space-around;
}

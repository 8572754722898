@import "./assets/icons/icon.scss";
@import "./assets/fonts/fonts.scss";
@import "./assets/fontawesome/css/all.css";

@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";

* {
  animation-duration: 0s;
  -webkit-text-size-adjust: none;
  font-family: "Confortaa-Regular";
}

:root {
  --primary-color: #661615;
  --secondary-color: #c8273d;
  --neutral-color: #ffffff;

  --dark-color: #202020;
  --semidark-color: #2b2b2b;
  --grey-color: #9d9d9c;
  --lightgrey-color: #4d4d4d;
  --darkgrey-color: #3d3d3d;

  --fa-primary-color: #ffffff;
  --fa-secondary-color: #661615;
  --fa-primary-opacity: #ffffff;
  --fa-secondary-opacity: #661615;
  --secondary-color-light: #c95262;
}

h1 {
  font-family: "Poppins-Regular";
  line-height: 1.2em;
}

.ant-radio-button-wrapper {
  background-color: transparent;
  color: white;
}

.ant-radio-button-wrapper:hover {
  color: var(--secondary-color);
  border-color: var(--secondary-color);
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child,
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child {
  background-color: transparent;
  color: var(--secondary-color);
  border-color: var(--secondary-color);
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child:hover,
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: var(--neutral-color);
  border-color: var(--neutral-color);
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
  background-color: var(--neutral-color);
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: var(--secondary-color);
}

.ant-btn.ant-btn-background-ghost::after {
  border: none !important;
  display: none;
}

.ant-btn:after {
  -webkit-animation: none !important;
  -moz-animation: none !important;
  -o-animation: none !important;
  -ms-animation: none !important;
  animation: none !important;
  display: none;
}

nz-content,
nz-layout {
  background: transparent !important;
}

.uiFooter {
  height: 38px !important;
  font-size: 11px;
  flex: 0 0;
  padding: 10px 24px;
  background: linear-gradient(to right, #661615, #C8273D);
  box-shadow: 0 0 10px rgba(0, 0, 0, 1);
  color: var(--neutral-color);
  position: fixed;
  bottom: 0;
  width: 100vw;
  z-index: 1000;
  border-radius: 30px 30px 0 0;

  .ocalab {
    color: var(--neutral-color);
  }

  .ocalab:hover {
    color: #fdc300;
  }

  i {
    margin-left: 10px;
    margin-right: 5px;
  }

  .footerLink {
    color: var(--neutral-color);
    text-decoration-color: var(--neutral-color);
    text-decoration: underline;
  }

  .footerRight {
    text-align: right;
  }
}

/* LOGIN */
.uiLoginHeader {
  padding: 0 24px;

  .uiHeaderLoginRow {
    height: 100%;
    text-align: center;

    .uiUncollapsedLogo {
      width: 200px;
      height: auto;
    }
  }
}

.uiLoginAdminContainer {
  background-color: var(--primary-color);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .uiUncollapsedLogo {
    width: 400px;
    max-width: 80%;
    height: auto;
    margin-top: 0;
  }

  .uiFormContainer {
    width: 80%;
    max-width: 500px;

    .uiLoginTitle {
      color: var(--neutral-color);
      font-size: 3rem;
    }

    img {
      margin-top: 4rem;
      margin-bottom: 2rem;
    }

    .uiDescriptionText {
      margin: 2rem 0;
      color: var(--neutral-color);
    }

    a {
      color: var(--grey-color);
      text-decoration: underline;
      text-decoration-color: var(--grey-color);
    }

    input {
      background-color: var(--grey-color);
      color: var(--neutral-color);
      width: 100%;
      padding: 10px;
    }

    input::placeholder {
      color: var(--neutral-color);
    }

    .loginBtn {
      margin-top: 1rem;
      width: 100%;
      background-color: var(--dark-color);
      border: 1px solid var(--grey-color);
      padding: 0 2rem;
      color: var(--neutral-color);
      border-radius: 5px;
      height: 50px;
    }

    .loginBtn:hover,
    .loginBtn:active {
      background-color: var(--neutral-color);
      border: 1px solid var(--primary-color);
      color: var(--primary-color);
    }

    .errorMessage {
      color: var(--neutral-color);
      margin-top: 2rem;
    }

    .uiAlertMessage {
      color: var(--neutral-color);
    }
  }
}

.uiLoginContainer {
  background-color: var(--dark-color);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .uiUncollapsedLogo {
    width: 400px;
    max-width: 80%;
    height: auto;
    margin-top: 0;
  }

  .uiFormContainer {
    width: 80%;
    max-width: 500px;

    .uiLoginTitle {
      color: var(--neutral-color);
      font-size: 3rem;
    }

    img {
      margin-top: 4rem;
      margin-bottom: 2rem;
    }

    .uiDescriptionText {
      margin: 2rem 0;
      color: var(--neutral-color);
    }

    a {
      color: var(--grey-color);
      text-decoration: underline;
      text-decoration-color: var(--grey-color);
    }

    input {
      background-color: var(--grey-color);
      color: var(--neutral-color);
      width: 100%;
      padding: 10px;
    }

    input::placeholder {
      color: var(--neutral-color);
    }

    .marginBtn {
      margin-top: 1rem;
    }

    .loginBtn {
      width: 100%;
      background-color: var(--primary-color);
      border: 1px solid var(--grey-color);
      padding: 0 2rem;
      color: var(--neutral-color);
      border-radius: 5px;
      height: 50px;
    }

    .loginBtn:hover:enabled,
    .loginBtn:active:enabled {
      background-color: var(--neutral-color);
      border: 1px solid var(--secondary-color);
      color: var(--secondary-color);
    }

    .pinloginBtn {
      background-color: #2c6615;
    }

    .pinloginBtn:disabled {
      opacity: 0.5;
    }

    .pinloginBtn:hover:enabled,
    .pinloginBtn:active:enabled {
      border: 1px solid #2c6615;
      color: #2c6615;
    }

    .errorMessage {
      color: var(--secondary-color);
      margin-top: 2rem;
    }

    .uiAlertMessage {
      color: var(--secondary-color);
    }
  }
}

/* ADMIN */
.uiHeader {
  padding: 0 24px;
  background-color: var(--semidark-color);
  color: var(--neutral-color);

  .uiHeaderRow {
    height: 100%;

    .uiHeaderLeftCol {
      height: 100%;
      display: flex;
      align-items: center;

      .uiUncollapsedLogo {
        margin-right: 2rem;
        width: 200px;
        display: inline-block;
        vertical-align: middle;
      }

      .uiCollapsedLogo {
        width: 30px;
        display: inline-block;
        vertical-align: middle;
      }

      .uiSidebarTrigger {
        color: var(--neutral-color) !important;
        cursor: pointer;
        font-size: 20px;
        width: auto;
        margin: 0 2rem;
        display: flex;
        align-items: center;
      }

      .uiSidebarTrigger:hover {
        color: var(--secondary-color) !important;
      }

      .uiTitleContainer {
        display: inline-block;
        height: 100%;
      }
    }

    .uiHeaderCenterCol {
      text-align: center;
    }

    .uiHeaderRightCol {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 100%;
    }
  }
}

.uiContainer {
  display: flex;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .uiSidebar {
    position: relative;
    z-index: 10;
    min-height: calc(100vh - 64px - 37px);
    background: var(--semidark-color);

    .ant-menu-inline,
    .ant-menu-vertical {
      border: none !important;
    }

    .uiSidebarLogo {
      position: relative;
      height: 64px;
      overflow: hidden;
      line-height: 64px;
      background: var(--secondary-color);
      text-align: center;
      margin-bottom: 1rem;
    }

    .uiMenu {
      background: var(--semidark-color);
      font-size: 16px;

      .ant-menu-submenu>.ant-menu {
        background-color: var(--semidark-color);
        color: var(--grey-color) !important;
      }

      .ant-menu-submenu-title:hover {
        background-color: var(--dark-color);
        color: var(--grey-color) !important;
      }

      .ant-menu-submenu-arrow {
        color: var(--grey-color) !important;
      }

      .ant-menu-submenu-active {
        background: var(--dark-color) !important;
        color: var(--grey-color) !important;

        a {
          color: var(--grey-color) !important;
        }

        .uiAdminIcon {
          margin-right: 1rem;
        }
      }

      .uiSubMenu {
        background-color: var(--dark-color);
        color: var(--grey-color);

        a {
          display: flex;
          align-items: center;
          color: var(--grey-color) !important;
        }

        a:hover,
        a:active {
          color: var(--grey-color) !important;
        }

        .uiSubMenuList {
          background-color: transparent;
          color: var(--neutral-color);

          .uiSubMenuItem {
            padding-left: 24px !important;
          }
        }

        .uiSubMenuItem:hover,
        .uiSubMenuItem:active {
          background-color: var(--primary-color);
          border: 1px solid var(--neutral-color);

          a {
            color: var(--neutral-color) !important;
          }
        }

        .uiSubMenuItem.ant-menu-item-selected {
          background-color: var(--primary-color);
          border: 1px solid var(--neutral-color);

          a {
            color: var(--neutral-color) !important;

            .uiDesignerIcon {
              background-image: url("./assets/icons/designer-hover.svg");
            }

            .uiSimulazioneIcon {
              background-image: url("./assets/icons/simulazione-hover.svg");
            }

            .uiUtentigruppiIcon {
              background-image: url("./assets/icons/utenti-gruppi-hover.svg");
            }

            .uiClientiaziendeIcon {
              background-image: url("./assets/icons/utenti-gruppi-hover.svg");
            }

            .uiPubblicazioneIcon {
              background-image: url("./assets/icons/pubblicazione-hover.svg");
            }

            .uiQuestionariIcon {
              background-image: url("./assets/icons/questionari-hover.svg");
            }

            .uiAzienda{
              background-image: url("./assets/icons/utenti-gruppi-hover.svg"); 
            }

            .uiDestinatariIcon {
              background-image: url("./assets/icons/destinatari-hover.svg");
            }

            .uiAggregatiIcon {
              background-image: url("./assets/icons/aggregati-hover.svg");
            }

            .uiValutazioniIcon {
              background-image: url("./assets/icons/valutazioni-hover.svg");
            }

            .uiPrivacyIcon {
              background-image: url("./assets/icons/privacy-hover.svg");
            }

            .uiQuestIcon {
              background-image: url("./assets/icons/quest-hover.svg");
            }
          }
        }

        .ant-menu-item-selected::after {
          display: none;
        }
      }

      .uiSubMenu .ant-menu-submenu-title {
        display: flex;
        align-items: center;
        margin: 0 !important;
      }

      .uiSubMenu .ant-menu-submenu-title span {
        font-size: 14px;
        line-height: normal;
      }

      .uiDesignMenu>.ant-menu-submenu-title:before {
        display: block;
        content: " ";
        background-image: url("./assets/icons/designer.svg");
        background-size: 20px 20px;
        height: 20px;
        width: 20px;
        margin-right: 10px;
      }

      .uiAmministrazioneMenu>.ant-menu-submenu-title:before {
        display: block;
        content: " ";
        background-image: url("./assets/icons/amministrazione.svg");
        background-size: 20px 20px;
        height: 20px;
        width: 20px;
        margin-right: 10px;
      }

      .uiGestioneMenu>.ant-menu-submenu-title:before {
        display: block;
        content: " ";
        background-image: url("./assets/icons/gestione.svg");
        background-size: 20px 20px;
        height: 20px;
        width: 20px;
        margin-right: 10px;
      }

      .ant-menu-item:hover {
        color: var(--grey-color) !important;
      }
    }

    .uiCollapsedMenu {
      background: var(--semidark-color);
      font-size: 16px;

      .ant-menu-submenu>.ant-menu {
        background-color: var(--semidark-color);
        color: var(--grey-color) !important;
      }

      .ant-menu-submenu-title:hover {
        color: var(--grey-color) !important;
      }

      .ant-menu-submenu-arrow {
        color: var(--grey-color) !important;
      }

      .ant-menu-submenu-active {
        background: var(--dark-color) !important;
        color: var(--grey-color) !important;

        a {
          color: var(--grey-color) !important;
        }

        .uiAdminIcon {
          margin-right: 1rem;
        }
      }

      .uiSubMenu {
        background-color: var(--dark-color);
        color: var(--grey-color);

        a {
          display: flex;
          align-items: center;
          color: var(--grey-color) !important;
        }

        a:hover,
        a:active {
          color: var(--grey-color) !important;
        }

        .uiSubMenuList {
          li {
            padding-left: 16px !important;
            display: flex;
            justify-content: center;
            align-items: center;

            span {
              display: none;
            }
          }
        }

        .uiSubMenuItem:hover,
        .uiSubMenuItem:active {
          background-color: var(--primary-color);
          border: 1px solid var(--neutral-color);

          a {
            color: var(--neutral-color) !important;
          }
        }

        .uiSubMenuItem.ant-menu-item-selected {
          background-color: var(--primary-color);
          border: 1px solid var(--neutral-color);

          a {
            color: var(--neutral-color) !important;

            .uiDesignerIcon {
              background-image: url("./assets/icons/designer-hover.svg");
            }

            .uiSimulazioneIcon {
              background-image: url("./assets/icons/simulazione-hover.svg");
            }

            .uiUtentigruppiIcon {
              background-image: url("./assets/icons/utenti-gruppi-hover.svg");
            }

            .uiClientiaziendeIcon {
              background-image: url("./assets/icons/utenti-gruppi-hover.svg");
            }

            .uiPubblicazioneIcon {
              background-image: url("./assets/icons/pubblicazione-hover.svg");
            }

            .uiQuestionariIcon {
              background-image: url("./assets/icons/questionari-hover.svg");
            }

            .uiAzienda{
              background-image: url("./assets/icons/utenti-gruppi-hover.svg"); 
            }

            .uiDestinatariIcon {
              background-image: url("./assets/icons/destinatari-hover.svg");
            }

            .uiAggregatiIcon {
              background-image: url("./assets/icons/aggregati-hover.svg");
            }

            .uiValutazioniIcon {
              background-image: url("./assets/icons/valutazioni-hover.svg");
            }

            .uiPrivacyIcon {
              background-image: url("./assets/icons/privacy-hover.svg");
            }

            .uiQuestIcon {
              background-image: url("./assets/icons/quest-hover.svg");
            }
          }
        }

        .ant-menu-item-selected::after {
          display: none;
        }

        .uiSubMenuItem i {
          margin: 0;
        }
      }

      .uiSubMenu .ant-menu-submenu-title span {
        display: none;
      }

      .uiSubMenu .ant-menu-submenu-title {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 !important;
        padding: 0 !important;
      }

      .uiDesignMenu>.ant-menu-submenu-title:before {
        display: block;
        content: " ";
        background-image: url("./assets/icons/designer.svg");
        background-size: 20px 20px;
        height: 20px;
        width: 20px;
        margin-right: 10px;
      }

      .uiAmministrazioneMenu>.ant-menu-submenu-title:before {
        display: block;
        content: " ";
        background-image: url("./assets/icons/amministrazione.svg");
        background-size: 20px 20px;
        height: 20px;
        width: 20px;
        margin-right: 10px;
      }

      .uiGestioneMenu>.ant-menu-submenu-title:before {
        display: block;
        content: " ";
        background-image: url("./assets/icons/gestione.svg");
        background-size: 20px 20px;
        height: 20px;
        width: 20px;
        margin-right: 10px;
      }

      .ant-menu-item:hover {
        color: var(--grey-color) !important;
      }
    }
  }

  .uiAdminInnerLayout {
    min-height: calc(100vh - 64px - 37px);

    .uiAdminContent {
      background-color: #d6d6d6 !important;

      .uiAdminInnerContent {
        height: auto;
        height: 100%;
      }
    }

    .uiAdminFooter {
      background: var(--secondary-color);
      color: var(--terziary-color) !important;
    }
  }
}

/* HOMEPAGE */
.uiHomeInnerLayout {
  width: 100%;
  height: 100%;

  .uiInnerHomeContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .uiHomeLogo {
      width: 70%;
    }
  }
}

/* INNER PAGE */
.uiPageInnerLayout {
  width: 100%;
  height: calc(100vh - 64px - 37px);
}

/* DESIGNER */
.uiDesignerContent {
  background-color: #4d4d4d !important;
  display: flex;
  width: 100%;
  flex-direction: column;

  .uiDesignerHeader {
    height: 5%;
    display: flex;
    justify-content: space-between;
    margin: 1rem 1rem 0 1rem;

    .uiDesignerLeftHeader {
      width: 70%;

      .uiSerchbar,
      .uiSerchbar>input {
        height: 100%;
        background-color: transparent;
        color: var(--neutral-color);
      }
    }

    .uiDesignerRightHeader {
      width: 30%;
      text-align: right;

      .uiNewQuestButton {
        width: 90%;
        height: 100%;
        background-color: var(--primary-color);
        border: 1px solid var(--semidark-color);
        color: var(--neutral-color);
        border-radius: 7px;

        .uiArrowDropdownIcon {
          font-size: 10px;
          position: absolute;
          right: 15px;
          top: 30%;
        }
      }
    }
  }

  .uiQuestsTableContainer {
    margin: 1rem;
    height: calc(95% - 3rem);

    .uiQuestsTable {
      height: 100%;
      background-color: var(--semidark-color);
      border: 1px solid #7d7d7d;

      thead {
        tr {
          th {
            background-color: var(--grey-color);
            color: var(--neutral-color);
            padding: 0.3rem 0;
            font-size: 12px;
            line-height: 1rem;
            border: 1px solid #808080;
          }
        }
      }

      tbody {
        .uiDarkColorRow {
          background-color: #d6d6d6;
          color: var(--dark-color);
          min-height: 45px;

          td {
            background-color: #d6d6d6;
            color: var(--dark-color);
            padding: 0.5rem 1rem;
            border: 1px solid #a1a1a0;
          }

          .uiVersionsCol {
            padding: 0;

            .uiBlackCol {
              background-color: var(--semidark-color) !important;
              border-bottom: 0;
              position: absolute;
              height: 100%;
              top: 0;
              width: 40%;
              display: inline-block;
              left: 0;
            }

            .uiVersionCode {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 60%;
              height: 100%;
              position: absolute;
              right: 0;
              top: 0;
            }
          }
        }

        .uiLightColorRow {
          background-color: #e6e6e6;
          color: var(--dark-color);

          td {
            background-color: #e6e6e6;
            color: var(--dark-color);
            padding: 0.5rem 1rem;
            border: 1px solid #a1a1a0;
          }

          .uiVersionsCol {
            padding: 0;

            .uiBlackCol {
              background-color: var(--semidark-color) !important;
              border-bottom: 0;
              position: absolute;
              height: 100%;
              top: 0;
              width: 40%;
              display: inline-block;
              left: 0;
            }

            .uiVersionCode {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 60%;
              height: 100%;
              position: absolute;
              right: 0;
              top: 0;
            }
          }
        }

        .uiBlackCol {
          background-color: var(--semidark-color) !important;
          padding: 0;
        }

        .uiGreenCol {
          background-color: #e7ffcd !important;
        }

        .ant-table-placeholder {
          td {
            border: none !important;
          }

          .ant-table-expanded-row-fixed {
            width: auto !important;
          }

          background-color: var(--darkgrey-color);
          pointer-events: none;

          nz-embed-empty {
            background-color: #4d4d4d;

            nz-empty {
              color: var(--neutral-color);
            }
          }
        }

        tr:hover td {
          background-color: rgba(157, 157, 156, 0.3);
        }
      }
    }
  }
}

.uiButtonSubMenu {
  width: 60%;
  text-align: center;
  margin-right: 0;
  margin-left: auto;
  padding: 0;

  :first-child {
    border-top: 1px solid var(--primary-color);
  }

  li {
    margin: 0;
    border-left: 1px solid var(--primary-color);
    border-right: 1px solid var(--primary-color);
    border-bottom: 1px solid var(--primary-color);
  }

  li:hover {
    background-color: var(--primary-color);
    color: var(--neutral-color);
  }
}

/* QUEST */
.uiQuestSidebar {
  position: relative;
  z-index: 10;
  min-height: calc(100vh - 64px - 37px);
  background: var(--semidark-color);

  .uiQuestSidebarLogo {
    height: 64px;
    padding: 0 24px;
    background-color: var(--semidark-color);
    color: var(--neutral-color);
    display: flex;
    justify-content: center;
    align-items: center;

    .uiUncollapsedLogo {
      width: 165px;
      display: inline-block;
      vertical-align: middle;
    }

    .uiCollapsedLogo {
      width: 30px;
      display: inline-block;
      vertical-align: middle;
    }
  }

  .ant-menu-inline,
  .ant-menu-vertical {
    border: none !important;
  }

  .uiSidebarLogo {
    position: relative;
    height: 64px;
    overflow: hidden;
    line-height: 64px;
    background: var(--secondary-color);
    text-align: center;
    margin-bottom: 1rem;
  }

  .uiQuestLanguage {
    .ant-menu-item-selected::after {
      display: none;
    }

    .uiHeadSubLi {
      background-color: #1d1d1d;
      color: var(--neutral-color) !important;
      margin: 0;
      text-align: center;
      padding: 0 !important;
      cursor: default;
      pointer-events: none;
    }

    .uiHeadLi {
      color: var(--neutral-color) !important;
      margin: 0;

      .ant-menu-submenu-title {
        color: var(--neutral-color) !important;
        background-color: #1d1d1d;
        text-align: center;
        margin: 0;
        width: 100% !important;

        .ant-menu-submenu-arrow {
          color: var(--neutral-color);
        }
      }

      .uiSubMenuList {
        background-color: var(--grey-color);
        color: var(--neutral-color);

        .uiSubMenuLangItem {
          margin: 0;
          text-align: center;
          padding: 0 0.5rem !important;
          width: 100% !important;
        }
      }

      .uiSubMenuLangItem.ant-menu-submenu-active {
        background: #1d1d1d !important;
        color: var(--grey-color) !important;

        .ant-menu-submenu-title {
          color: var(--neutral-color) !important;
          background-color: #1d1d1d;
        }

        border: none !important;
      }

      .uiSubMenuLangSelectItem {
        pointer-events: none;
        border: none !important;
        color: var(--neutral-color);
        background-color: var(--secondary-color);
      }

      .uiSubMenuLangItem::after {
        display: none;
      }

      .uiSubMenuLangItem:hover {
        color: var(--secondary-color);
      }
    }

    .uiHeadLi.ant-menu-submenu-active {
      background: #1d1d1d !important;
      color: var(--grey-color) !important;

      .ant-menu-submenu-title {
        color: var(--neutral-color) !important;
        background-color: #1d1d1d;
      }
    }
  }

  .uiMenu {
    background-color: transparent;

    .uiMenuItem {
      background-color: transparent;

      a {
        background-color: transparent;
        color: var(--grey-color);
        display: flex;
        align-items: center;
      }
    }

    .uiMenuItem:hover,
    .uiMenuItem:active,
    .uiMenuItem:focus {
      background-color: var(--primary-color);
      color: var(--neutral-color);
      border: 1px solid var(--neutral-color);

      a {
        color: var(--neutral-color);
      }
    }

    .uiMenuItem.ant-menu-item-selected {
      background-color: var(--primary-color);
      border: 1px solid var(--neutral-color);

      a {
        color: var(--neutral-color) !important;
      }
    }

    .uiMenuItem.ant-menu-item-disabled {
      a {
        background-color: transparent;
        color: var(--grey-color) !important;
        display: flex;
        align-items: center;
        opacity: 0.3;
      }
    }

    .uiCancelMenuItem {
      position: absolute;
      bottom: 41px;
      color: #fff;
      background-color: var(--darkgrey-color);
      margin: 0;

      a {
        background-color: transparent;
        color: var(--neutral-color);
        display: flex;
        align-items: center;
      }
    }

    .uiLastMenuItem {
      position: absolute;
      bottom: 1px;
      color: #fff;
      background-color: var(--darkgrey-color);
      margin: 0;

      a {
        background-color: transparent;
        color: var(--neutral-color);
        display: flex;
        align-items: center;
      }
    }

    .ant-menu-item-selected::after {
      display: none;
    }

    .uiMenuCollapsedItem {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        margin: 0 !important;
      }
    }

    .uiActionItem.ant-menu-item-selected {
      background-color: transparent;
      border: none;

      a {
        background-color: transparent;
        color: var(--grey-color) !important;
        display: flex;
        align-items: center;
      }
    }
  }
}

.uiQuestSidebar.ant-layout-sider-collapsed {
  flex: 0 0 95px !important;
  max-width: 95px !important;
  min-width: 95px !important;
  width: 95px !important;

  .uiCollapsedMenu {
    width: 100%;
    background-color: transparent;

    .uiMenuItem {
      background-color: transparent;

      a {
        background-color: transparent;
        color: var(--grey-color);
        display: flex;
        align-items: center;
      }
    }

    .uiMenuItem:hover,
    .uiMenuItem:active,
    .uiMenuItem:focus {
      background-color: var(--primary-color);
      color: var(--neutral-color);
      border: 1px solid var(--neutral-color);

      a {
        color: var(--neutral-color);
      }
    }

    .uiMenuItem.ant-menu-item-selected {
      background-color: var(--primary-color);
      border: 1px solid var(--neutral-color);

      a {
        color: var(--neutral-color) !important;
      }
    }

    .ant-menu-item-selected::after {
      display: none;
    }

    .uiCancelMenuItem {
      position: absolute;
      bottom: 41px;
      color: #fff;
      background-color: var(--darkgrey-color);
      margin: 0;
      width: 100%;

      a {
        background-color: transparent;
        color: var(--neutral-color);
        display: flex;
        align-items: center;
      }
    }

    .uiLastMenuItem {
      position: absolute;
      bottom: 1px;
      color: #fff;
      background-color: var(--darkgrey-color);
      margin: 0;
      width: 100%;

      a {
        background-color: transparent;
        color: var(--neutral-color);
        display: flex;
        align-items: center;
      }
    }

    .uiMenuCollapsedItem {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        margin: 0 !important;
      }
    }
  }
}

.uiQuestHeader {
  background-color: var(--primary-color);
}

.uiNewQuestContainer {
  display: flex;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .uiQuestInnerLayout {
    min-height: calc(100vh - 64px - 37px);

    .uiQuestContent {
      background-color: #4d4d4d !important;

      .uiQuestInnerContent {
        height: auto;
        height: 100%;
      }
    }
  }
}

/* QUEST HEAD */
.uiQuestHeadContent {
  padding: 0;

  .uiQuestTableRow {
    padding: 0 1rem calc(1rem + 40px) 1rem;
    margin: 0 !important;
  }

  .uiAnagraficaTableContainer {
    margin: 1rem;

    height: calc(100% - 542px);

    .uiBkgContainer {
      height: 100%;
      background-color: var(--semidark-color);
    }
  }

  .uiAnagraficaTable {
    color: var(--neutral-color);
    height: 100%;
    border: 1px solid #9d9d9c;

    thead {
      tr {
        th {
          background-color: var(--grey-color);
          color: var(--neutral-color);
          padding: 0 0.5rem;
          border: 1px solid #808080;
        }
      }
    }

    tbody {
      .uiDarkColorRow {
        background-color: #4d4d4d;
      }

      .uiLightColorRow {
        background-color: #5e5e5e;
      }

      tr {
        color: var(--neutral-color);

        td {
          padding: 0.5rem 1rem;
          border: 1px solid #a1a1a0;

          .uiTableInput {
            background-color: transparent !important;
            border: none;
            box-shadow: none;
            color: var(--neutral-color);
          }

          .uiTableSelect {
            width: 100%;
            box-shadow: none;
            color: var(--neutral-color);

            nz-select-top-control {
              background-color: transparent;
              box-shadow: none !important;
              border: none;

              nz-select-item {
                background-color: transparent;
                border: none;
                color: var(--neutral-color);

                .ant-select-selection-item-remove {
                  padding-left: 1rem;
                  color: var(--neutral-color);
                }
              }
            }

            nz-select-arrow {
              color: var(--neutral-color);
            }
          }

          .uiSemSelect {
            color: var(--neutral-color);
            width: 100%;
            background-color: transparent;
            border: none;
            min-height: 32px;
            box-shadow: none;

            .uiSemOption {
              min-height: 32px;
              color: rgba(0, 0, 0, 0.85);
            }
          }
        }

        .uiEditedCol {
          color: var(--neutral-color);

          .uiQuestSelect {
            width: 100%;
            box-shadow: none;
            color: var(--neutral-color);

            nz-select-top-control {
              background-color: transparent;
              box-shadow: none !important;
              border: none;

              nz-select-item {
                background-color: transparent;
                border: none;
                color: var(--neutral-color);

                .ant-select-selection-item-remove {
                  padding-left: 1rem;
                  color: var(--neutral-color);
                }
              }
            }

            nz-select-arrow {
              color: var(--neutral-color);
            }
          }

          nz-switch {
            .ant-switch {
              border: 1px solid var(--neutral-color);
            }

            .ant-switch-handle::before {
              background-color: var(--neutral-color);
            }
          }
        }

        .uiGreenCol {
          background-color: #e7ffcd;
        }

        .uiOptionCol {
          padding-top: 0;
          padding-bottom: 0;

          .uiBorderCol {
            border-right: 1px solid var(--neutral-color);
          }
        }

        .uiNoEditedCol {
          background-color: #2b2c2c;
        }

        .uiBlueSelect {
          background-color: #cde3f6;

          nz-select-item {
            background-color: transparent;
            border: none;
            color: var(--dark-color) !important;

            .ant-select-selection-item-remove {
              padding-left: 1rem;
              color: var(--dark-color) !important;
            }
          }

          nz-select-arrow {
            color: var(--dark-color) !important;
          }
        }

        .uiGreenSelect {
          background-color: #cdf6cf;

          nz-select-item {
            background-color: transparent;
            border: none;
            color: var(--dark-color) !important;

            .ant-select-selection-item-remove {
              padding-left: 1rem;
              color: var(--dark-color) !important;
            }
          }

          nz-select-arrow {
            color: var(--dark-color) !important;
          }
        }

        .uiYellowSelect {
          background-color: #f3f6cd;

          nz-select-item {
            background-color: transparent;
            border: none;
            color: var(--dark-color) !important;

            .ant-select-selection-item-remove {
              padding-left: 1rem;
              color: var(--dark-color) !important;
            }
          }

          nz-select-arrow {
            color: var(--dark-color) !important;
          }
        }

        .uiRedSelect {
          background-color: #f6cdcd;

          nz-select-item {
            background-color: transparent;
            border: none;
            color: var(--dark-color) !important;

            .ant-select-selection-item-remove {
              padding-left: 1rem;
              color: var(--dark-color) !important;
            }
          }

          nz-select-arrow {
            color: var(--dark-color) !important;
          }
        }

        .uiBlackSelect {
          background-color: #a1a1a1;
        }
      }

      nz-embed-empty {
        background-color: #4d4d4d;

        nz-empty {
          color: var(--neutral-color);
        }
      }

      tr:hover .uiEditedCol {
        background-color: transparent !important;
      }

      tr:hover .uiNoEditedCol {
        background-color: #2b2c2c !important;
      }

      tr:hover .uiEditedCol.uiSem.uiBlueSelect {
        background-color: #cde3f6 !important;
        opacity: 0.9;
      }

      tr:hover .uiEditedCol.uiSem.uiGreenSelect {
        background-color: #cdf6cf !important;
        opacity: 0.9;
      }

      tr:hover .uiEditedCol.uiSem.uiYellowSelect {
        background-color: #f3f6cd !important;
        opacity: 0.9;
      }

      tr:hover .uiEditedCol.uiSem.uiRedSelect {
        background-color: #f6cdcd !important;
        opacity: 0.9;
      }

      tr:hover .uiEditedCol.uiSem.uiBlackSelect {
        background-color: #a1a1a1 !important;
        opacity: 0.9;
      }

      .ant-table-placeholder {
        td {
          border: none !important;
        }

        .ant-table-expanded-row-fixed {
          width: auto;
        }

        pointer-events: none;
        background-color: var(--darkgrey-color);

        nz-embed-empty {
          background-color: #4d4d4d;

          nz-empty {
            color: var(--neutral-color);
          }
        }
      }
    }
  }

  .uiQuestParagraphRow {
    width: 100%;
    color: var(--grey-color);
    padding: 1rem;
    margin: 0 !important;
    background-color: #3d3d3d;
    display: flex;
    align-items: center;

    .uiParagraphLeft {
      height: 22px;
      color: var(--grey-color);
      display: flex;
      align-items: center;
    }

    .uiParagraphRight {
      display: flex;
      height: 22px;
      align-items: center;
      justify-content: flex-end;
      color: var(--grey-color);

      i {
        margin-left: 0.5rem;
      }

      .uiAddAnagrafica {
        background-color: transparent;
        border: none;
        display: flex;
        align-items: center;
        color: var(--grey-color);
        box-shadow: none !important;
        padding: 0;

        span {
          color: var(--neutral-color);
        }
      }

      .uiAddAnagrafica:hover {
        .uiPlusCircleIcon {
          background-image: url("./assets/icons/plus-circle-hover.svg");
        }
      }
    }
  }

  .uiQuestFieldsRow {
    background-color: var(--lightgrey-color);
    padding: 1rem 1rem;
    margin: 0 !important;
    font-size: 12px;

    .uiQuestFieldsFlex {
      display: flex;
      flex-direction: row;

      .uiGreyCol {
        background-color: var(--grey-color);
        color: var(--neutral-color);
        padding: 0.5rem;
        height: 55px;
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 130px;

        p {
          margin: 0;
          line-height: 1;
        }
      }

      .uiBlackCol {
        background-color: var(--semidark-color);
        color: var(--neutral-color);
        padding: 0.5rem;
        border: 1px solid var(--grey-color);
        height: 55px;
        display: flex;
        align-items: center;
        width: 100%;

        p {
          margin: 0;
          line-height: 1;
        }

        font-size: 12px;
      }

      .uiLightGreyCol {
        background-color: #e6e6e6;
        color: var(--dark-color);
        padding: 0.5rem 0.5rem 0.5rem 0.5rem;
        border: 1px solid var(--grey-color);
        height: 55px;
        display: flex;
        align-items: center;
        width: 100%;

        p {
          margin: 0;
          line-height: 1;
        }

        nz-select {
          font-size: 12px;
          width: 100%;
          background-color: transparent;
          border: none;

          nz-select-top-control {
            background-color: transparent;
            border: none;
            box-shadow: none !important;
            padding: 0;
            width: 100%;
          }

          nz-select-arrow {
            right: 0;
          }
        }
      }

      .uiGreenCol {
        background-color: #e7ffcd;
        color: var(--dark-color);
        padding: 0.5rem 0.5rem 0.5rem 0.5rem;
        border: 1px solid var(--grey-color);
        height: 55px;
        display: flex;
        align-items: center;
        width: 100%;

        p {
          margin: 0;
          line-height: 1;
        }

        nz-select {
          font-size: 12px;
          width: 100%;
          background-color: transparent;
          border: none;

          nz-select-top-control {
            background-color: transparent;
            border: none;
            box-shadow: none !important;
            padding: 0;
            width: 100%;
          }

          nz-select-arrow {
            right: 0;
          }
        }
      }

      nz-upload {
        width: 100%;

        .ant-upload {
          width: 100%;
        }

        .uiUploadBtn {
          box-shadow: none;
          background-color: transparent;
          border: none;
          width: 100%;
          padding: 0;
        }

        nz-upload-list {
          display: none;
        }
      }

      .uiYellowCol {
        background-color: #fff2cc;
        color: var(--grey-color);
        padding: 0.5rem 0 0.5rem 0.5rem;
        border: 1px solid var(--grey-color);
        height: 55px;
        display: flex;
        align-items: center;
        width: 100%;

        .uiUploadBtn {
          box-shadow: none;
          background-color: transparent;
          border: none;
          width: 100%;
          padding: 0;
        }
      }
    }

    .uiLeftFlex {
      justify-content: left;
    }

    .uiRightFlex {
      justify-content: right;
    }

    .uiRowCol {

      /*INPUT */
      .uiHeadInput {
        width: 100%;
        background-color: transparent;
        border: none;
        box-shadow: none;
      }

      /*SELECT */
      .uiHeadSelect {
        width: 100%;
        box-shadow: none;
        color: var(--neutral-color);
        font-size: 12px;

        nz-select-top-control {
          background-color: transparent;
          box-shadow: none !important;
          border: none;

          nz-select-item {
            background-color: #5d5d5d;
            border: none;
            color: var(--neutral-color);

            .ant-select-selection-item-remove {
              padding-left: 1rem;
              color: var(--neutral-color);
            }
          }
        }
      }

      .uiNoCancel {
        nz-select-top-control {
          nz-select-item {
            .ant-select-selection-item-remove {
              display: none;
            }
          }
        }
      }
    }
  }
}

.cdk-overlay-pane.uiSemOptions {
  .ant-select-dropdown {
    padding: 0;
  }

  nz-option-item:nth-child(1) {
    background-color: #cde3f6;
  }

  nz-option-item:nth-child(2) {
    background-color: #cdf6cf;
  }

  nz-option-item:nth-child(3) {
    background-color: #f3f6cd;
  }

  nz-option-item:nth-child(4) {
    background-color: #f6cdcd;
  }

  nz-option-item:nth-child(5) {
    background-color: #a1a1a1;
  }
}

/* QUEST QUESTIONS */
.uiQuestQuesionContent {
  .uiQuestParagraphRow {
    height: 7%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    background-color: #3d3d3d;

    .uiParagraphLeft {
      height: 22px;
      color: var(--grey-color);
      font-size: 14px;
    }

    .uiParagraphRight {
      font-size: 14px;
      height: 22px;

      i {
        margin-left: 0.5rem;
      }

      .uiAddQuestion {
        background-color: transparent;
        border: none;
        display: flex;
        align-items: center;
        color: var(--neutral-color);
        box-shadow: none !important;
      }

      .uiAddQuestion:hover {
        .uiPlusCircleIcon {
          background-image: url("./assets/icons/plus-circle-hover.svg");
        }
      }
    }
  }

  .uiQuestionHeader {
    height: 4%;
    margin-top: 1rem;
    display: flex;
    padding: 0 1rem;

    .uiSerchbar,
    .uiSerchbar>input {
      background-color: transparent;
      color: var(--neutral-color);
    }
  }

  .uiQuestQuesionBody {
    height: calc(89% - 3rem);
    margin: 1rem;
    background-color: var(--semidark-color);

    .uiQuestionTable {
      background-color: transparent;

      thead {
        tr {
          th {
            background-color: var(--grey-color);
            color: var(--neutral-color);
            padding: 0.3rem 0;
            font-size: 12px;
            line-height: 1rem;
            border: 1px solid #808080;
          }
        }
      }

      tbody {
        background-color: #4d4d4d;

        .uiDarkColorRow {
          background-color: var(--lightgrey-color);
          color: var(--neutral-color);

          td {
            color: var(--neutral-color);
            padding: 0.5rem 1rem;
            border: 1px solid #a1a1a0;
          }
        }

        .uiLightColorRow {
          background-color: #5d5d5d;
          color: var(--neutral-color);

          td {
            color: var(--neutral-color);
            padding: 0.5rem 1rem;
            border: 1px solid #a1a1a0;
          }
        }

        nz-embed-empty {
          background-color: #4d4d4d;

          nz-empty {
            color: var(--neutral-color);
          }
        }

        tr:hover td {
          background-color: rgba(157, 157, 156, 0.3);
        }

        .ant-table-placeholder {
          td {
            border: none !important;
          }

          .ant-table-expanded-row-fixed {
            width: auto !important;
          }

          background-color: var(--darkgrey-color);
          pointer-events: none;

          nz-embed-empty {
            background-color: #4d4d4d;

            nz-empty {
              color: var(--neutral-color);
            }
          }
        }
      }
    }
  }
}

/* ADD QUESTION */
.uiQuestAddQuesionContent {
  .uiQuestParagraphRow {
    width: 100%;
    height: 7%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    background-color: #3d3d3d;

    .uiParagraphLeft {
      height: 22px;
      color: var(--grey-color);
    }

    .uiParagraphRight {
      height: 22px;
      display: flex;
      align-items: center;

      a {
        display: flex;
        align-items: center;
        color: var(--grey-color);
      }

      a:hover {
        color: var(--secondary-color);
      }

      .uiQuestPagination {
        color: var(--neutral-color);

        .ant-pagination-prev {
          .ant-pagination-item-link {
            color: var(--neutral-color);
          }
        }

        .ant-pagination-simple-pager {
          input {
            color: var(--semidark-color);
          }
        }

        .ant-pagination-next {
          .ant-pagination-item-link {
            color: var(--neutral-color);
          }
        }
      }
    }
  }

  .uiQuestAddBody {
    padding: 1rem;
    padding-bottom: calc(1rem + 38px);

    .uiQuestCode {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #3d3d3d;
      border: 1px solid #777777;
      color: var(--neutral-color);

      .uiGridCol {
        padding: 0.5rem 1rem;
        border-right: 1px solid #777777;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .uiAnswerInput {
          background-color: transparent;
          color: var(--neutral-color);
          border: none;
          box-shadow: none;
        }

        .ant-switch.ant-switch-checked {
          background-color: #e7ffcd;
        }
      }
    }

    .uiEmptyCol {
      background-color: var(--semidark-color);
    }

    .uiQuestFilterRow {
      background-color: #3d3d3d;
      border: 1px solid #777777;
      color: var(--neutral-color);

      .uiGridCol {
        padding: 0.5rem 0;
        border-right: 1px solid #777777;
        display: flex;
        align-items: center;
        justify-content: center;

        .uiFilterSelect {
          width: 100%;
          box-shadow: none;
          color: var(--neutral-color);

          nz-select-top-control {
            background-color: transparent;
            box-shadow: none !important;
            border: none;

            nz-select-item {
              background-color: transparent;
              border: none;
              color: var(--neutral-color);

              .ant-select-selection-item-remove {
                padding-left: 1rem;
                color: var(--neutral-color);
              }
            }
          }

          nz-select-arrow {
            color: var(--neutral-color);
          }
        }

        .uiFilterInput {
          width: 100%;
          background-color: transparent;
          border: none;
          box-shadow: none;
          color: var(--neutral-color);
        }

        .uiFilterData {
          width: 100%;
          background-color: transparent;
          border: none;
          box-shadow: none;

          input {
            color: var(--neutral-color);
          }

          .ant-picker-suffix {
            color: var(--neutral-color);
          }

          .anticon-close-circle {
            background-color: #3d3d3d;

            svg {
              color: #fff;
            }
          }
        }

        .uiAnswerSwitch {
          line-height: 30px;

          .ant-switch {
            border: 1px solid #777777;

            .ant-switch-handle::before {
              background-color: var(--darkgrey-color);
            }
          }
        }
      }
    }

    .uiQuestText {
      background-color: #5d5d5d;

      .uiGridCol {
        background-color: #5d5d5d;

        textarea {
          border: 1px solid #777777;
          border-top: none;
          color: var(--neutral-color);
          background-color: #5d5d5d;
        }
      }
    }

    .uiQuestAnswer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #3d3d3d;
      border: 1px solid #777777;
      color: var(--neutral-color);

      .uiGridCol {
        padding: 0.5rem 1rem;
        display: flex;
        align-items: center;
      }

      .uiRightAlign {
        display: flex;
        align-items: center;
        justify-content: right;
      }
    }

    .uiYellowRow {
      background-color: #fff2cc;
      color: var(--grey-color);
      border-bottom: 1px solid var(--grey-color);
    }

    .uiQuestCode {
      .uiAnswerSwitch {
        .ant-switch {
          border: 1px solid #777777;

          .ant-switch-handle::before {
            background-color: var(--neutral-color);
          }
        }
      }

      .uiAnswerSwitch {
        .ant-switch-checked {
          .ant-switch-handle::before {
            background-color: var(--darkgrey-color);
          }
        }
      }
    }
  }
}

/* TRAITS */
.uiQuestTraitContent {
  .uiQuestParagraphRow {
    height: 7%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    background-color: #3d3d3d;

    .uiParagraphLeft {
      height: 22px;
      color: var(--grey-color);
    }

    .uiParagraphRight {
      height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        margin-left: 0.5rem;
      }

      .uiAddQuestion {
        background-color: transparent;
        border: none;
        display: flex;
        align-items: center;
        color: var(--grey-color);
        box-shadow: none !important;
      }

      .uiAddQuestion:hover {
        .uiPlusCircleIcon {
          background-image: url("./assets/icons/plus-circle-hover.svg");
        }
      }

      .uiTraitPagination {
        color: var(--neutral-color);

        .ant-pagination-prev {
          .ant-pagination-item-link {
            color: var(--neutral-color);
          }
        }

        .ant-pagination-simple-pager {
          input {
            color: var(--semidark-color);
          }
        }

        .ant-pagination-next {
          .ant-pagination-item-link {
            color: var(--neutral-color);
          }
        }
      }
    }
  }

  .uiTraitHeader {
    height: 5%;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;

    .uiQuestTraitInfo {
      height: 100%;

      .uiGroupSelect {
        width: 100%;
        box-shadow: none;
        color: var(--neutral-color);
        font-size: 12px;
        padding: 0;

        nz-select-top-control {
          background-color: transparent;
          box-shadow: none !important;
          border: none;

          nz-select-item {
            background-color: #5d5d5d;
            border: none;
            color: var(--neutral-color);

            .ant-select-selection-item-remove {
              padding-left: 1rem;
              color: var(--neutral-color);
            }
          }
        }
      }

      .uiGridCol {
        height: 100%;
        padding: 0.5rem 1rem;
        border-right: 1px solid #777777;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .uiAnswerInput {
          height: 100%;
          background-color: transparent;
          color: var(--neutral-color);
          border: none;
          box-shadow: none;
        }
      }

      .uiGreyCol {
        background-color: var(--grey-color);
        color: var(--neutral-color);
        padding: 0.5rem;
        display: flex;
        align-items: center;
        width: 100%;

        p {
          margin: 0;
          line-height: 1;
        }
      }

      .uiBlackCol {
        background-color: var(--semidark-color);
        color: var(--neutral-color);
        padding: 0.5rem;
        border: 1px solid var(--grey-color);
        display: flex;
        align-items: center;
        width: 100%;

        p {
          margin: 0;
          line-height: 1;
        }

        font-size: 12px;
      }

      .uiSelectCol {
        padding: 0;
      }

      .uiRedSelect {
        background-color: #ff0000 !important;

        nz-select-item {
          background-color: #ff0000 !important;
        }
      }

      .uiOrangeSelect {
        background-color: #e97949 !important;

        nz-select-item {
          background-color: #e97949 !important;
        }
      }

      .uiBlueSelect {
        background-color: #00b0f0 !important;

        nz-select-item {
          background-color: #00b0f0 !important;
        }
      }

      .uiGreenSelect {
        background-color: #92d050 !important;

        nz-select-item {
          background-color: #92d050 !important;
        }
      }

      .uiYellowSelect {
        background-color: #ffff00 !important;

        nz-select-item {
          background-color: #ffff00 !important;
        }
      }
    }
  }

  .uiTraitTableHead {
    height: 4%;
    display: flex;
    padding: 0 1rem;
    margin-top: 1rem;

    .uiSerchbar,
    .uiSerchbar>input {
      background-color: transparent;
      color: var(--neutral-color);
    }

    .uiFilterBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      border: 1px solid var(--neutral-color);
      border-left: none;
      padding: 0 50px;
      background-color: transparent;
    }

    .uiFilterActiveBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      padding: 0 50px;
      border: 1px solid var(--neutral-color);
      border-left: none;
      background-color: #fdf0cd;
    }

    .uiTransformBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      border: 1px solid var(--neutral-color);
      background-color: transparent;
      padding: 0 30px;
    }
  }

  .uiQuestTraitBody {
    margin: 1rem;
    height: calc(84% - 4rem);
    background-color: var(--semidark-color);

    .uiTraitTable {
      background-color: transparent;

      thead {
        tr {
          th {
            background-color: var(--grey-color);
            color: var(--neutral-color);
            padding: 0.3rem 0;
            font-size: 12px;
            line-height: 1rem;
            border: 1px solid #808080;
          }
        }
      }

      tbody {
        background-color: #4d4d4d;

        .uiDarkColorRow {
          background-color: var(--lightgrey-color);
          color: var(--neutral-color);

          td {
            color: var(--neutral-color);
            padding: 0.5rem 1rem;
            border: 1px solid #a1a1a0;

            .uiPuntInput {
              background-color: transparent;
              color: var(--neutral-color);
              border: none;
              text-align: center;
              width: 100%;

              .ant-input-number-handler-wrap {
                right: -5px;
              }

              .ant-input-number-input-wrap>input {
                text-align: center;
              }
            }
          }

          .uiYellowInput {
            background-color: #faf2c6;

            .uiPuntInput {
              color: var(--semidark-color);
            }
          }

          .uiNoInput {
            pointer-events: none;
            background-color: var(--dark-color);
            border-left: none;
            border-right: none;
          }

          .uiQuestCol {
            i {
              position: absolute;
              right: 10px;
              top: 25%;
            }
          }
        }

        .uiLightColorRow {
          background-color: #5d5d5d;
          color: var(--neutral-color);

          td {
            color: var(--neutral-color);
            padding: 0.5rem 1rem;
            border: 1px solid #a1a1a0;
          }
        }

        nz-embed-empty {
          background-color: #4d4d4d;

          nz-empty {
            color: var(--neutral-color);
          }
        }

        tr:hover td {
          background-color: rgba(157, 157, 156, 0.3);
        }

        .uiFilterRow {
          td {
            color: var(--neutral-color);
            padding: 0.5rem 0.5rem;
            border: 1px solid #a1a1a0;
          }

          .uiEmptyCol {
            pointer-events: none;
            background-color: var(--dark-color);
          }

          .uiFilterCol {
            padding: 0;
          }

          background-color: #3d3d3d;
          border: 1px solid #777777;
          color: var(--neutral-color);

          .uiGridCol {
            padding: 0.5rem;
            border-right: 1px solid #777777;
            display: flex;
            align-items: center;
            justify-content: center;

            .uiFilterSelect {
              width: 100%;
              box-shadow: none;
              color: var(--neutral-color);

              nz-select-top-control {
                background-color: transparent;
                box-shadow: none !important;
                border: none;

                nz-select-item {
                  background-color: transparent;
                  border: none;
                  color: var(--neutral-color);

                  .ant-select-selection-item-remove {
                    padding-left: 1rem;
                    color: var(--neutral-color);
                  }
                }
              }

              nz-select-arrow {
                color: var(--neutral-color);
              }
            }

            .uiFilterInput {
              width: 100%;
              background-color: transparent;
              border: none;
              box-shadow: none;
              color: var(--neutral-color);
            }

            .uiFilterData {
              width: 100%;
              background-color: transparent;
              border: none;
              box-shadow: none;

              input {
                color: var(--neutral-color);
              }

              .ant-picker-suffix {
                color: var(--neutral-color);
              }

              .anticon-close-circle {
                background-color: #3d3d3d;

                svg {
                  color: #fff;
                }
              }
            }
          }

          .uiRemoveCol {
            border-right: none;
          }

          .uiPuntInput {
            background-color: transparent;
            color: var(--neutral-color);
            border: none;
            text-align: center;
            width: 100%;

            .ant-input-number-handler-wrap {
              right: -5px;
            }

            .ant-input-number-input-wrap>input {
              text-align: center;
            }
          }

          .uiYellowInput {
            background-color: #faf2c6;

            .uiPuntInput {
              color: var(--semidark-color);
            }
          }

          .uiNoInput {
            pointer-events: none;
            background-color: var(--dark-color);
            border-left: none;
            border-right: none;
          }
        }

        .ant-table-placeholder {
          td {
            border: none !important;
          }

          .ant-table-expanded-row-fixed {
            width: auto !important;
          }

          background-color: var(--darkgrey-color);
          pointer-events: none;

          nz-embed-empty {
            background-color: #4d4d4d;

            nz-empty {
              color: var(--neutral-color);
            }
          }
        }
      }
    }
  }

  .uiEmptyTraitContainer {
    height: calc(93% - 2rem);
    margin: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--semidark-color);

    nz-empty {
      .ant-empty-image {
        height: auto;
      }
    }
  }
}

/* ADD TRAIT */
.uiQuestAddTraitContent {
  .uiQuestParagraphRow {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    background-color: #3d3d3d;

    .uiParagraphLeft {
      height: 22px;
      color: var(--grey-color);
    }

    .uiParagraphRight {
      height: 22px;
      display: flex;
      align-items: center;

      a {
        display: flex;
        align-items: center;
        color: var(--grey-color);
      }

      a:hover {
        color: var(--secondary-color);
      }
    }
  }
}

.cdk-overlay-pane.uiGroupOptions {
  .ant-select-dropdown {
    padding: 0;
  }

  nz-option-item:nth-child(1) {
    background-color: #fd0000;
  }

  nz-option-item:nth-child(2) {
    background-color: #e97949;
  }

  nz-option-item:nth-child(3) {
    background-color: #00b0f0;
  }

  nz-option-item:nth-child(4) {
    background-color: #92d050;
  }

  nz-option-item:nth-child(5) {
    background-color: #ffff00;
  }
}

/* ATTENDIBILITY */
.uiQuestAttendContent {
  .uiQuestParagraphRow {
    width: 100%;
    height: 7%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    background-color: #3d3d3d;

    .uiParagraphLeft {
      color: var(--grey-color);

      .uiAttendIndex {
        background-color: #5e5e5e;
        color: var(--neutral-color);
        border: 1px solid #a1a1a0;
        text-align: center;
        margin-left: 1rem;

        .ant-input-number-input-wrap>input {
          text-align: center;
        }
      }
    }

    .uiParagraphRight {
      font-size: 14px;
      height: 22px;

      i {
        margin-left: 0.5rem;
      }

      .uiAddAttend {
        background-color: transparent;
        border: none;
        display: flex;
        align-items: center;
        color: var(--neutral-color);
        box-shadow: none !important;
      }

      .uiAddAttend:hover {
        .uiPlusCircleIcon {
          background-image: url("./assets/icons/plus-circle-hover.svg");
        }
      }
    }
  }

  .uiQustAttendBody {
    margin: 1rem;
    background-color: var(--semidark-color);
    height: calc(93% - 2rem);

    .uiAttendTable {
      margin-top: 1rem;
      background-color: transparent;

      thead {
        tr {
          th {
            background-color: var(--grey-color);
            color: var(--neutral-color);
            padding: 0.3rem 0;
            font-size: 12px;
            line-height: 1rem;
            border: 1px solid #808080;
          }
        }
      }

      tbody {
        background-color: #4d4d4d;

        td {
          color: var(--neutral-color);
          padding: 0.5rem 1rem;
          border: 1px solid #a1a1a0;

          .uiPuntInput {
            background-color: transparent;
            color: var(--neutral-color);
            border: none;
            text-align: center;
            width: 100%;

            .ant-input-number-handler-wrap {
              right: -5px;
            }

            .ant-input-number-input-wrap>input {
              text-align: center;
            }
          }

          .uiPuntTraitInput {
            background-color: transparent;
            color: var(--neutral-color);
            border: none;
            text-align: left;
            width: 100%;

            .ant-input-number-handler-wrap {
              right: -5px;
            }

            .ant-input-number-input-wrap>input {
              text-align: left;
            }
          }

          .uiAttendInput {
            background-color: transparent;
            color: var(--neutral-color);
            border: none;
            box-shadow: none;
          }

          .uiQuestSelect {
            width: 100%;
            box-shadow: none;
            color: var(--neutral-color);

            nz-select-top-control {
              background-color: transparent;
              box-shadow: none !important;
              border: none;

              nz-select-item {
                background-color: transparent;
                border: none;
                color: var(--neutral-color);

                .ant-select-selection-item-remove {
                  padding-left: 1rem;
                  color: var(--neutral-color);
                }
              }
            }

            nz-select-arrow {
              color: var(--neutral-color);
            }
          }

          .uiQuestSelectOperator {
            text-align: center;
          }
        }

        .uiAttendOps {
          padding: 0;

          .uiOpsCol {
            border-right: 1px solid #808080;
          }

          .uiEmptyCol {
            background-color: var(--semidark-color);
            padding: 1.5rem 0;
          }

          .uiFilterCol {
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .uiFilterLastCol {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        nz-embed-empty {
          background-color: #4d4d4d;

          nz-empty {
            color: var(--neutral-color);
          }
        }

        tr:hover td {
          background-color: rgba(157, 157, 156, 0.3);
        }

        .ant-table-placeholder {
          td {
            border: none !important;
          }

          .ant-table-expanded-row-fixed {
            width: auto !important;
          }

          background-color: var(--darkgrey-color);
          pointer-events: none;

          nz-embed-empty {
            background-color: #4d4d4d;

            nz-empty {
              color: var(--neutral-color);
            }
          }
        }
      }
    }
  }
}

/* SWITCH */
nz-switch {
  .ant-switch {
    background: transparent;
    border: 1px solid var(--darkgrey-color);

    .ant-switch-handle {
      width: 15px;
      height: 15px;
    }

    .ant-switch-handle::before {
      background-color: var(--darkgrey-color);
    }
  }
}

/* RECOVER MODAL */
.innerRecoverModal {
  .ant-modal-content {
    h1 {
      font-weight: bold;
    }

    background-color: #d6d6d6 !important;

    .modalDesc {
      margin-bottom: 1rem;
    }

    .loginBtn {
      width: 100%;
      background-color: var(--primary-color);
      border: 1px solid var(--grey-color);
      padding: 0 2rem;
      color: var(--neutral-color);
      border-radius: 5px;
      height: 50px;
    }

    .loginBtn:hover,
    .loginBtn:active {
      background-color: var(--neutral-color);
      border: 1px solid var(--secondary-color);
      color: var(--secondary-color);
    }

    .cancelBtn {
      margin-top: 1rem;
      width: 100%;
      background-color: var(--neutral-color);
      border: 1px solid var(--primary-color);
      padding: 0 2rem;
      color: var(--primary-color);
      border-radius: 5px;
      height: 50px;
    }

    .cancelBtn:hover,
    .cancelBtn:active {
      background-color: var(--neutral-color);
      border: 1px solid var(--secondary-color);
      color: var(--secondary-color);
    }

    .errorMessage {
      color: var(--secondary-color);
      margin-top: 2rem;
    }

    input {
      background-color: #9d9d9d;
      color: var(--neutral-color);
      width: 100%;
      margin-bottom: 1rem;
      padding: 10px;
    }

    input::placeholder {
      color: var(--neutral-color);
    }

    .ant-modal-footer {
      display: none;
    }
  }
}

.innerModal {
  .ant-modal-footer {
    border: none;
  }

  .ant-modal-content {
    h1 {
      font-weight: bold;
    }

    background-color: #d6d6d6 !important;

    .modalDesc {
      margin-bottom: 1rem;
    }

    .ant-btn-primary {
      margin-top: 1rem;
      width: 100%;
      background-color: var(--primary-color);
      border: 1px solid var(--grey-color);
      padding: 0 2rem;
      color: var(--neutral-color);
      border-radius: 5px;
      height: 50px;
    }

    .ant-btn-primary:hover,
    .ant-btn-primary:active {
      background-color: var(--neutral-color);
      border: 1px solid var(--secondary-color);
      color: var(--secondary-color);
    }

    .ant-btn:disabled {
      display: none;
    }

    .errorMessage {
      color: var(--secondary-color);
      margin-top: 2rem;
    }

    input {
      background-color: #9d9d9d;
      color: var(--neutral-color);
      width: 100%;
      margin-bottom: 1rem;
      padding: 10px;
    }

    input::placeholder {
      color: var(--neutral-color);
    }
  }
}

.innerEditorModal {
  .ant-modal {
    top: 10vh;
    max-height: 80vh;
  }

  .ant-modal-content {
    .ant-modal-body {
      padding-top: 50px;
    }

    background-color: #4d4d4d !important;
    color: #fff !important;

    quill-editor {
      margin-top: 1rem;

      .ql-editor {
        max-height: 60vh;
      }
    }

    .saveBtn {
      margin-top: 1rem;
      width: 100%;
      background-color: var(--primary-color);
      border: 1px solid var(--grey-color);
      padding: 0 2rem;
      color: var(--neutral-color);
      border-radius: 5px;
      height: 50px;
    }

    .saveBtn:hover,
    .saveBtn:active {
      background-color: var(--neutral-color);
      border: 1px solid var(--secondary-color);
      color: var(--secondary-color);
    }
  }

  .ant-modal-footer {
    display: none;
  }
}

.innerTransformModal {
  .ant-modal {
    top: 10vh;
    max-height: 80vh;
  }

  .ant-modal-content {
    .ant-modal-body {
      padding-top: 50px;
    }

    background-color: #4d4d4d !important;
    color: #fff !important;

    .uiTransformStickyRow {
      top: 0;
      border-bottom: 1px solid white;
      position: sticky;
      margin-top: 0px;
    }

    .uiTransformContainer {
      max-height: 60vh;
      overflow-y: scroll;
      margin-top: 1rem;
    }

    .uiTransformRow {
      margin-bottom: 1rem;

      .uiTransformInput {
        background-color: var(--grey-color);
        color: var(--neutral-color);
      }

      .uiErrorInput {
        border: 1px solid var(--primary-color);
        box-shadow: 0 0 0 2px var(--primary-color);
      }
    }

    .saveBtn {
      margin-top: 1rem;
      width: 100%;
      background-color: var(--primary-color);
      border: 1px solid var(--grey-color);
      padding: 0 2rem;
      color: var(--neutral-color);
      border-radius: 5px;
      height: 50px;
    }

    .saveBtn:hover,
    .saveBtn:active {
      background-color: var(--neutral-color);
      border: 1px solid var(--secondary-color);
      color: var(--secondary-color);
    }
  }

  .ant-modal-footer {
    display: none;
  }
}

.uiGreyTableRow {
  background-color: #4d4d4d;

  td {
    background-color: #4d4d4d;
  }
}

.uiLightTableRow {
  background-color: #5e5e5e;

  td {
    background-color: #5e5e5e;
  }
}

.uiEmptyContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  nz-empty {
    .ant-empty-image {
      height: auto;
    }
  }
}

.uiPdfModal {
  width: 90vw !important;
  height: 100%;
  top: 0 !important;
  margin: 0 auto;
  padding: 1rem 0;

  .ant-modal-content {
    width: 100%;
    height: 100%;

    .ant-modal-body {
      padding: 0;
      width: 100%;
      height: calc(100% - 48px);
    }
  }
}

.uiImgModal {
  .ant-modal-content {
    background-color: #ececec;
    max-width: fit-content;

    .ant-modal-body {
      padding: 0;
    }
  }
}

/* UTENTI */
.uiUtentiContent {
  background-color: #4d4d4d !important;
  display: flex;
  width: 100%;
  flex-direction: column;

  .uiUtentiHeader {
    height: 5%;
    display: flex;
    justify-content: space-between;
    margin: 1rem 1rem 0 1rem;

    .uiUtentiLeftHeader {
      width: 70%;

      .uiSerchbar,
      .uiSerchbar>input {
        height: 100%;
        background-color: transparent;
        color: var(--neutral-color);
      }
    }

    .uiUtentiRightHeader {
      width: 30%;
      text-align: right;

      .uiNewUserButton {
        width: 90%;
        height: 100%;
        background-color: var(--primary-color);
        border: 1px solid var(--semidark-color);
        color: var(--neutral-color);
        border-radius: 7px;
      }
    }
  }

  .uiUsersTableContainer {
    margin: 1rem;
    height: calc(95% - 3rem);

    .ant-select-multiple .ant-select-selection-search {
      display: none !important;
    }

    .ant-select-selector {
      cursor: pointer !important;
    }

    .uiUsersTable {
      height: 100%;
      background-color: var(--semidark-color);
      border: 1px solid #7d7d7d;

      thead {
        tr {
          th {
            background-color: var(--grey-color);
            color: var(--neutral-color);
            padding: 0.3rem 0;
            font-size: 12px;
            line-height: 1rem;
            border: 1px solid #808080;
          }
        }
      }

      tbody {
        .uiDarkColorRow {
          background-color: #d6d6d6;
          color: var(--dark-color);
          height: 45px;

          td {
            background-color: #d6d6d6;
            color: var(--dark-color);
            padding: 0.5rem 0.5rem;
            border: 1px solid #a1a1a0;
          }
        }

        .uiLightColorRow {
          background-color: #e6e6e6;
          color: var(--dark-color);

          td {
            background-color: #e6e6e6;
            color: var(--dark-color);
            padding: 0.5rem 0.5rem;
            border: 1px solid #a1a1a0;
          }
        }

        .uiBlackCol {
          background-color: var(--semidark-color) !important;
          padding: 0;
        }

        .uiGreenCol {
          background-color: #e7ffcd !important;
        }

        .uiOnCol {
          background-color: #e7ffcd !important;
        }

        .uiOffCol {
          background-color: #ffe6cb !important;
        }

        .ant-table-placeholder {
          td {
            border: none !important;
          }

          .ant-table-expanded-row-fixed {
            width: auto !important;
          }

          background-color: var(--darkgrey-color);
          pointer-events: none;

          nz-embed-empty {
            background-color: #4d4d4d;

            nz-empty {
              color: var(--neutral-color);
            }
          }
        }

        tr {
          td {
            .uiFilterSelect {
              width: 100%;
              background: none;
              border: none;

              nz-select-item {
                background: none;
                border: none;

                .ant-select-selection-item-remove {
                  display: none;
                }
              }
            }
          }
        }

        tr:hover td {
          background-color: rgba(157, 157, 156, 0.3);
        }

        .ant-table-placeholder {
          td {
            border: none !important;
          }

          .ant-table-expanded-row-fixed {
            width: auto !important;
          }

          background-color: var(--darkgrey-color);
          pointer-events: none;

          nz-embed-empty {
            background-color: #4d4d4d;

            nz-empty {
              color: var(--neutral-color);
            }
          }
        }
      }
    }
  }
}

/* CLIENTI */
.uiClientiContent {
  background-color: #4d4d4d !important;
  display: flex;
  width: 100%;
  flex-direction: column;

  .uiClientiHeader {
    height: 5%;
    display: flex;
    justify-content: space-between;
    margin: 1rem 1rem 0 1rem;

    .uiClientiLeftHeader {
      width: 70%;

      .uiSerchbar,
      .uiSerchbar>input {
        height: 100%;
        background-color: transparent;
        color: var(--neutral-color);
      }
    }

    .uiClientiRightHeader {
      width: 30%;
      text-align: right;

      .uiNewUserButton {
        width: 90%;
        height: 100%;
        background-color: var(--primary-color);
        border: 1px solid var(--semidark-color);
        color: var(--neutral-color);
        border-radius: 7px;
      }
    }
  }

  .uiClientiTableContainer {
    margin: 1rem;
    height: calc(95% - 3rem);

    .uiClientiTable {
      height: 100%;
      background-color: var(--semidark-color);
      border: 1px solid #7d7d7d;

      thead {
        tr {
          th {
            background-color: var(--grey-color);
            color: var(--neutral-color);
            padding: 0.3rem 0;
            font-size: 12px;
            line-height: 1rem;
            border: 1px solid #808080;
          }
        }
      }

      tbody {
        .uiDarkColorRow {
          background-color: #d6d6d6;
          color: var(--dark-color);
          height: 45px;

          td {
            background-color: #d6d6d6;
            color: var(--dark-color);
            padding: 0.5rem 0.5rem;
            border: 1px solid #a1a1a0;
          }
        }

        .uiLightColorRow {
          background-color: #e6e6e6;
          color: var(--dark-color);

          td {
            background-color: #e6e6e6;
            color: var(--dark-color);
            padding: 0.5rem 0.5rem;
            border: 1px solid #a1a1a0;
          }
        }

        .uiBlackCol {
          background-color: var(--semidark-color) !important;
          padding: 0;
        }

        .uiGreenCol {
          background-color: #e7ffcd !important;
        }

        .uiOnCol {
          background-color: #e7ffcd !important;
        }

        .uiOffCol {
          background-color: #ffe6cb !important;
        }

        .uiYellowCol {
          background-color: #fff2cc !important;
        }

        .ant-table-placeholder {
          td {
            border: none !important;
          }

          .ant-table-expanded-row-fixed {
            width: auto !important;
          }

          background-color: var(--darkgrey-color);
          pointer-events: none;

          nz-embed-empty {
            background-color: #4d4d4d;

            nz-empty {
              color: var(--neutral-color);
            }
          }
        }

        tr {
          td {
            .uiFilterSelect {
              width: 100%;
              background: none;
              border: none;

              nz-select-item {
                background: none;
                border: none;

                .ant-select-selection-item-remove {
                  display: none;
                }
              }
            }

            nz-upload {
              width: 100%;

              .ant-upload {
                width: 100%;
              }

              .uiUploadBtn {
                box-shadow: none;
                background-color: transparent;
                border: none;
                width: 100%;
                padding: 0;
              }

              nz-upload-list {
                display: none;
              }
            }

            .uiUploadBtn {
              box-shadow: none;
              background-color: transparent;
              border: none;
              width: 100%;
              padding: 0;
            }
          }
        }

        tr:hover td {
          background-color: rgba(157, 157, 156, 0.3);
        }
      }
    }
  }
}

/* LICENZE */
.uiLicenzeContent {
  background-color: #4d4d4d !important;
  display: flex;
  width: 100%;
  flex-direction: column;

  .uiLicenzeHeader {
    height: 5%;
    display: flex;
    justify-content: space-between;
    margin: 1rem 1rem 0 1rem;

    .uiLicenzeLeftHeader {
      width: 70%;

      .uiSerchbar,
      .uiSerchbar>input {
        height: 100%;
        background-color: transparent;
        color: var(--neutral-color);
      }
    }

    .uiLicenzeRightHeader {
      width: 30%;
      text-align: right;

      .uiFilterButton {
        width: 90%;
        height: 100%;

        border: 1px solid var(--semidark-color);
        color: var(--semidark-color);
        border-radius: 7px;
      }

      .uiFilterButton.uiOnFilter {
        background-color: #fff2cc;
      }

      .uiFilterButton.uiOffFilter {
        background-color: var(--neutral-color);
      }
    }
  }

  .uiLicenzeTableContainer {
    margin: 1rem;
    height: calc(95% - 3rem);

    .uiLicenzeTable {
      height: 100%;
      background-color: var(--semidark-color);
      border: 1px solid #7d7d7d;

      thead {
        tr {
          th {
            background-color: var(--grey-color);
            color: var(--neutral-color);
            padding: 0.3rem 0;
            font-size: 12px;
            line-height: 1rem;
            border: 1px solid #808080;
          }
        }
      }

      tbody {
        .uiDarkColorRow {
          background-color: #d6d6d6;
          color: var(--dark-color);
          height: 45px;

          td {
            background-color: #d6d6d6;
            color: var(--dark-color);
            padding: 0.5rem 1rem;
            border: 1px solid #a1a1a0;
          }
        }

        .uiLightColorRow {
          background-color: #e6e6e6;
          color: var(--dark-color);

          td {
            background-color: #e6e6e6;
            color: var(--dark-color);
            padding: 0.5rem 1rem;
            border: 1px solid #a1a1a0;
          }
        }

        .uiBlackCol {
          background-color: var(--semidark-color) !important;
          padding: 0;
        }

        .uiGreyCol {
          background-color: #9d9d9d !important;
        }

        .uiGreenCol {
          background-color: #e7ffcd !important;
        }

        .uiOnCol {
          background-color: #e7ffcd !important;
        }

        .uiOffCol {
          background-color: #ffe6cb !important;
        }

        .uiYellowCol {
          background-color: #fff2cc !important;
        }

        .ant-table-placeholder {
          td {
            border: none !important;
          }

          .ant-table-expanded-row-fixed {
            width: auto !important;
          }

          background-color: var(--darkgrey-color);
          pointer-events: none;

          nz-embed-empty {
            background-color: #4d4d4d;

            nz-empty {
              color: var(--neutral-color);
            }
          }
        }

        tr {
          td {
            .uiFilterSelect {
              width: 100%;
              background: none;
              border: none;

              nz-select-item {
                background: none;
                border: none;

                .ant-select-selection-item-remove {
                  display: none;
                }
              }
            }

            .uiQuestSelect {
              background: none;
              border: none;
              width: calc(100% - 30px);

              nz-select-item {
                background: none;
                border: none;

                .ant-select-selection-item-remove {
                  display: none;
                }
              }
            }

            nz-upload {
              width: 100%;

              .ant-upload {
                width: 100%;
              }

              .uiUploadBtn {
                box-shadow: none;
                background-color: transparent;
                border: none;
                width: 100%;
                padding: 0;
              }

              nz-upload-list {
                display: none;
              }
            }

            .uiUploadBtn {
              box-shadow: none;
              background-color: transparent;
              border: none;
              width: 100%;
              padding: 0;
            }
          }
        }

        tr:hover td {
          background-color: rgba(157, 157, 156, 0.3);
        }
      }
    }
  }
}

/* GESTIONE Q */
.uiGestioneContent {
  background-color: #4d4d4d !important;
  display: flex;
  width: 100%;
  flex-direction: column;

  .uiGestioneHeader {
    height: 5%;
    display: flex;
    justify-content: space-between;
    margin: 1rem 1rem 0 1rem;

    .uiSerchbar,
    .uiSerchbar>input {
      height: 100%;
      background-color: transparent;
      color: var(--neutral-color);
    }

    .uiFilterBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      border: 1px solid var(--neutral-color);
      border-left: none;
      padding: 0 50px;
      background-color: transparent;
    }

    .uiFilterActiveBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      padding: 0 50px;
      border: 1px solid var(--neutral-color);
      border-left: none;
      background-color: #fdf0cd;
    }
  }

  .uiGestioneTableContainer {
    margin: 1rem;
    height: calc(95% - 3rem);

    .uiGestioneTable {
      height: 100%;
      background-color: var(--semidark-color);
      border: 1px solid #7d7d7d;

      thead {
        tr {
          th {
            background-color: var(--grey-color);
            color: var(--neutral-color);
            padding: 0.3rem 0;
            font-size: 12px;
            line-height: 1rem;
            border: 1px solid #808080;
          }
        }
      }

      tbody {
        .uiDarkColorRow {
          background-color: #d6d6d6;
          color: var(--dark-color);
          height: 45px;

          td {
            background-color: #d6d6d6;
            color: var(--dark-color);
            padding: 0.5rem 1rem;
            border: 1px solid #a1a1a0;
          }
        }

        .uiLightColorRow {
          background-color: #e6e6e6;
          color: var(--dark-color);

          td {
            background-color: #e6e6e6;
            color: var(--dark-color);
            padding: 0.5rem 1rem;
            border: 1px solid #a1a1a0;
          }
        }

        .uiBlackCol {
          background-color: var(--semidark-color) !important;
          padding: 0;
        }

        .uiDarkGreyCol {
          background-color: #4d4d4d !important;
          color: var(--neutral-color) !important;
        }

        .uiNoEvents {
          pointer-events: none;
        }

        .uiGreenCol {
          background-color: #e7ffcd !important;
        }

        .uiOnCol {
          background-color: #e7ffcd !important;
        }

        .uiOffCol {
          background-color: #ffe6cb !important;
        }

        .uiYellowCol {
          background-color: #fff2cc !important;
        }

        .ant-table-placeholder {
          td {
            border: none !important;
          }

          .ant-table-expanded-row-fixed {
            width: auto !important;
          }

          background-color: var(--darkgrey-color);
          pointer-events: none;

          nz-embed-empty {
            background-color: #4d4d4d;

            nz-empty {
              color: var(--neutral-color);
            }
          }
        }

        tr {
          td {
            .uiFilterSelect {
              width: 100%;
              background: none;
              border: none;

              nz-select-item {
                background: none;
                border: none;

                .ant-select-selection-item-remove {
                  display: none;
                }
              }
            }

            .uiQuestSelect {
              background: none;
              border: none;
              width: calc(100% - 30px);

              nz-select-item {
                background: none;
                border: none;

                .ant-select-selection-item-remove {
                  display: none;
                }
              }
            }

            nz-upload {
              width: 100%;

              .ant-upload {
                width: 100%;
              }

              .uiUploadBtn {
                box-shadow: none;
                background-color: transparent;
                border: none;
                width: 100%;
                padding: 0;
              }

              nz-upload-list {
                display: none;
              }
            }

            .uiUploadBtn {
              box-shadow: none;
              background-color: transparent;
              border: none;
              width: 100%;
              padding: 0;
            }

            nz-switch {
              .ant-switch {
                border: 1px solid var(--grey-color);
              }

              .ant-switch-handle::before {
                background-color: var(--grey-color);
              }
            }
          }
        }

        tr:hover td {
          background-color: rgba(157, 157, 156, 0.3);
        }
      }
    }
  }
}

// GESTIONE Q-EDIT
.uiEditSidebar {
  position: relative;
  z-index: 10;
  min-height: calc(100vh - 64px - 37px);
  background: var(--semidark-color);

  .uiQuestSidebarLogo {
    height: 64px;
    padding: 0 24px;
    background-color: var(--semidark-color);
    color: var(--neutral-color);
    display: flex;
    justify-content: center;
    align-items: center;

    .uiUncollapsedLogo {
      width: 165px;
      display: inline-block;
      vertical-align: middle;
    }

    .uiCollapsedLogo {
      width: 30px;
      display: inline-block;
      vertical-align: middle;
    }
  }

  .ant-menu-inline,
  .ant-menu-vertical {
    border: none !important;
  }

  .uiSidebarLogo {
    position: relative;
    height: 64px;
    overflow: hidden;
    line-height: 64px;
    background: var(--secondary-color);
    text-align: center;
    margin-bottom: 1rem;
  }

  .uiMenu {
    background-color: transparent;

    .uiLastMenuItem {
      position: absolute;
      bottom: 1px;
      color: #fff;
      background-color: var(--darkgrey-color);

      a {
        background-color: transparent;
        color: var(--neutral-color);
        display: flex;
        align-items: center;
      }
    }

    .uiMenuItem {
      margin: 0;

      a {
        background-color: transparent;
        color: var(--grey-color);
        display: flex;
        align-items: center;

        i {
          margin-right: 15px;
        }
      }
    }

    .uiMenuItem:hover,
    .uiMenuItem:active,
    .uiMenuItem:focus {
      background-color: var(--primary-color);
      color: var(--neutral-color);
      border: 1px solid var(--neutral-color);

      a {
        color: var(--neutral-color);
      }
    }

    .uiMenuItem.ant-menu-item-selected {
      background-color: var(--primary-color);
      border: 1px solid var(--neutral-color);

      a {
        color: var(--neutral-color) !important;
      }
    }

    .uiMenuCollapsedItem {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        margin: 0 !important;
      }
    }

    .uiActionItem.ant-menu-item-selected {
      background-color: transparent;
      border: none;

      a {
        background-color: transparent;
        color: var(--grey-color) !important;
        display: flex;
        align-items: center;
      }
    }
  }
}

.uiEditSidebar.ant-layout-sider-collapsed {
  flex: 0 0 95px !important;
  max-width: 95px !important;
  min-width: 95px !important;
  width: 95px !important;

  .uiCollapsedMenu {
    width: 100%;
    background-color: transparent;

    .uiMenuItem {
      background-color: #343434;
      margin: 0;

      a {
        background-color: transparent;
        color: var(--grey-color);
        display: flex;
        align-items: center;
      }
    }

    .uiLastMenuItem {
      position: absolute;
      bottom: 1px;
      color: #fff;
      background-color: var(--darkgrey-color);
      width: 100%;

      a {
        background-color: transparent;
        color: var(--neutral-color);
        display: flex;
        align-items: center;
      }
    }

    .uiMenuItem:hover,
    .uiMenuItem:active,
    .uiMenuItem:focus {
      background-color: var(--primary-color);
      color: var(--neutral-color);
      border: 1px solid var(--neutral-color);

      a {
        color: var(--neutral-color);
      }
    }

    .uiMenuItem.ant-menu-item-selected {
      background-color: var(--primary-color);
      border: 1px solid var(--neutral-color);

      a {
        color: var(--neutral-color) !important;
      }
    }

    .ant-menu-item-selected::after {
      display: none;
    }

    .uiMenuCollapsedItem {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        margin: 0 !important;
      }
    }
  }
}

.uiGestioneEditContent {
  .uiGestioneEditParagraphRow {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    background-color: #3d3d3d;
    margin: 0 !important;

    .uiParagraphLeft {
      color: var(--grey-color);
    }

    .uiParagraphRight {
      font-size: 14px;

      i {
        margin-left: 0.5rem;
      }

      .uiAddDest {
        background-color: transparent;
        border: none;
        display: flex;
        align-items: center;
        color: var(--neutral-color);
        box-shadow: none !important;
      }

      .uiAddDest:hover {
        .uiPlusCircleIcon {
          background-image: url("./assets/icons/plus-circle-hover.svg");
        }
      }
    }
  }

  background-color: #4d4d4d !important;
  display: flex;
  width: 100%;
  min-height: calc(100vh - 64px - 37px);
  flex-direction: column;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .uiGestioneEditHeader {
    height: 5%;
    display: flex;
    justify-content: space-between;
    margin: 1rem 1rem 0 1rem;

    .uiSerchbar,
    .uiSerchbar>input {
      height: 100%;
      background-color: transparent;
      color: var(--neutral-color);
    }
  }

  .uiGestioneEditTableContainer {
    margin: 1rem;
    height: calc(95% - 3rem);

    .uiGestioneEditTable {
      height: 100%;
      background-color: var(--semidark-color);
      border: 1px solid #7d7d7d;

      thead {
        tr {
          th {
            background-color: var(--grey-color);
            color: var(--neutral-color);
            padding: 0.3rem 0;
            font-size: 12px;
            line-height: 1rem;
            border: 1px solid #808080;
          }
        }
      }

      tbody {
        .uiDarkColorRow {
          background-color: #4d4d4d;
          color: var(--neutral-color);
          height: 45px;

          td {
            background-color: #4d4d4d;
            color: var(--neutral-color);
            padding: 0.5rem 1rem;
            border: 1px solid #a1a1a0;
          }
        }

        .uiLightColorRow {
          background-color: #5e5e5e;
          color: var(--neutral-color);

          td {
            background-color: #5e5e5e;
            color: var(--neutral-color);
            padding: 0.5rem 1rem;
            border: 1px solid #a1a1a0;
          }
        }

        tr {
          .uiLinkCol {

            p,
            span {
              margin: 0;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }

        .ant-table-placeholder {
          td {
            border: none !important;
          }

          .ant-table-expanded-row-fixed {
            width: auto !important;
          }

          background-color: var(--darkgrey-color);
          pointer-events: none;

          nz-embed-empty {
            background-color: #4d4d4d;

            nz-empty {
              color: var(--neutral-color);
            }
          }
        }

        tr:hover td {
          background-color: rgba(157, 157, 156, 0.3);
        }
      }
    }
  }
}

.uiDestSubMenu {
  width: 100%;
  text-align: center;
  margin-right: 0;
  margin-left: auto;
  padding: 0;

  :first-child {
    border-top: 1px solid var(--primary-color);
  }

  li {
    margin: 0;
    border-left: 1px solid var(--primary-color);
    border-right: 1px solid var(--primary-color);
    border-bottom: 1px solid var(--primary-color);
  }

  li:hover {
    background-color: var(--primary-color);
    color: var(--neutral-color);
  }
}

.uiDestSubEmptyMenu {
  width: 100%;
  text-align: center;
  margin-right: 0;
  margin-left: auto;
  padding: 0;

  nz-empty {
    .ant-empty-image {
      height: 50px;
    }

    .ant-empty-description {
      color: var(--grey-color);
    }
  }
}

//EDIT - V
.uiGestioneEditVContainer {
  margin: 1rem;
  height: calc(100% - 2rem);

  .uiGestioneEditVTable {
    .ant-table-header {
      overflow: hidden !important;
    }

    height: 40%;
    background-color: var(--semidark-color);
    border: 1px solid #7d7d7d;

    thead {
      tr {
        th {
          background-color: var(--darkgrey-color);
          color: var(--neutral-color);
          padding: 0.5rem 0;
          border: 1px solid #808080;
          line-height: 1;
        }
      }
    }

    tbody {
      .uiDarkColorRow {
        background-color: #4d4d4d;
        color: var(--neutral-color);
        height: 45px;

        td {
          background-color: #4d4d4d;
          color: var(--neutral-color);
          padding: 0.5rem 1rem;
          border: 1px solid #a1a1a0;
        }
      }

      .uiLightColorRow {
        background-color: #5e5e5e;
        color: var(--neutral-color);

        td {
          background-color: #5e5e5e;
          color: var(--neutral-color);
          padding: 0.5rem 1rem;
          border: 1px solid #a1a1a0;
        }
      }

      tr {
        .uiLinkCol {
          p {
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      .ant-table-placeholder {
        td {
          border: none !important;
        }

        .ant-table-expanded-row-fixed {
          width: auto;
        }

        background-color: var(--darkgrey-color);
        pointer-events: none;

        nz-embed-empty {
          background-color: #4d4d4d;

          nz-empty {
            color: var(--neutral-color);
          }
        }
      }

      tr:hover td {
        background-color: rgba(157, 157, 156, 0.3);
      }
    }
  }

  .uiGestioneEditVGrid {
    height: calc(24% - 1rem);
    margin-top: 1rem;

    .uiEditGridRow {
      height: 100%;

      .uiEditGridCol {
        max-height: calc(100vh - 76% - 1rem - 64px - 28px);
        height: 100%;
        border: 1px solid #a1a1a0;
      }

      .uiEditGridFirstCol {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--darkgrey-color);
        text-align: center;
      }

      .uiEditGridSecondCol {
        border-left: none;
        //background-color: #5e5e5e;
        background-color: #fff;
        color: var(--darkgrey-color);

        // color: var(--neutral-color);
        quill-editor {
          .ql-toolbar.ql-snow {
            height: 28%;
            border: none;
            border-bottom: 1px solid #a1a1a0;
          }

          .ql-container.ql-snow {
            height: 72%;
            border: none;
          }
        }
      }
    }
  }

  .uiValControls {
    height: calc(6% - 1rem);
    margin-top: 1rem;
    display: flex;
    align-items: center;
    width: 100%;

    .uiValControlsLeft {
      width: 50%;
      text-align: center;
    }

    .uiValControlsRight {
      width: 50%;
      text-align: center;
    }

    .uiValPositive {
      color: green;
      font-size: 25px;
      font-weight: 500;
    }

    .uiValNegative {
      color: rgb(200, 80, 80);
      font-size: 25px;
      font-weight: 500;
    }
  }

  .uiGestioneEditVGraph {
    height: calc(30% - 1rem);
    margin-top: 1rem;

    .uiEditGridRow {
      height: 100%;

      .uiEditGridCol {
        height: 100%;
        border: 1px solid #a1a1a0;
      }

      .uiEditGridFirstCol {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--darkgrey-color);
        text-align: center;

        span {
          writing-mode: vertical-lr;
          transform: rotate(180deg);
          color: #a1a1a0;
        }
      }

      .uiEditGridSecondCol {
        background-color: #fff; // #5e5e5e;
        color: var(--neutral-color);

        .chart {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .uiGestioneEditVBigGraph {
    height: calc(54% - 1rem);
  }
}

.uiDropdownSelect {
  nz-option-container {
    min-width: 400px;
    padding: 0;
  }

  nz-option-item {
    border-bottom: 1px solid var(--darkgrey-color);
    padding: 10px 20px;

    .ant-select-item-option-content {
      display: flex;
      align-items: center;
    }
  }

  .ant-select-item-option-selected {
    background: none;
  }

  .uiAllBoldOption {
    font-weight: 800;
    text-decoration: underline;
  }
}

/* GESTIONE P */
.uiGestionePrivacyHeader {
  height: 5%;
  display: flex;
  justify-content: space-between;
  margin: 1rem 1rem 0 1rem !important;

  .uiGestioneLeftHeader {
    width: 70%;

    .uiSerchbar,
    .uiSerchbar>input {
      height: 100%;
      background-color: transparent;
      color: var(--neutral-color);
    }
  }

  .uiGestioneRightHeader {
    width: 30%;
    text-align: right;

    .uiNewBtn {
      width: 90%;
      height: 100%;
      background-color: var(--primary-color);
      border: 1px solid var(--semidark-color);
      color: var(--neutral-color);
      border-radius: 7px;
    }
  }
}

// EDIT - P
.uiEditSidebar {
  .uiQuestLanguage {
    .ant-menu-item-selected::after {
      display: none;
    }

    .uiHeadLi {
      color: var(--neutral-color) !important;
      margin: 0;

      .ant-menu-submenu-title {
        color: var(--neutral-color) !important;
        background-color: #1d1d1d;
        text-align: center;
        margin: 0;
        width: 100% !important;

        .ant-menu-submenu-arrow {
          color: var(--neutral-color);
        }
      }

      .uiSubMenuList {
        background-color: var(--grey-color);
        color: var(--neutral-color);

        .uiSubMenuLangItem {
          margin: 0;
          text-align: center;
          padding: 0 0.5rem !important;
          width: 100% !important;
        }
      }

      .uiSubMenuLangItem.ant-menu-submenu-active {
        background: #1d1d1d !important;
        color: var(--grey-color) !important;

        .ant-menu-submenu-title {
          color: var(--neutral-color) !important;
          background-color: #1d1d1d;
        }

        border: none !important;
      }

      .uiSubMenuLangSelectItem {
        pointer-events: none;
        border: none !important;
        color: var(--neutral-color);
        background-color: var(--secondary-color);
      }

      .uiSubMenuLangItem::after {
        display: none;
      }

      .uiSubMenuLangItem:hover {
        color: var(--secondary-color);
      }
    }

    .uiHeadLi.ant-menu-submenu-active {
      background: #1d1d1d !important;
      color: var(--grey-color) !important;

      .ant-menu-submenu-title {
        color: var(--neutral-color) !important;
        background-color: #1d1d1d;
      }
    }
  }
}

.uiEditPrivacyParagraphRow {
  width: 100%;
  color: var(--grey-color);
  padding: 1rem;
  margin: 0 !important;
  background-color: #3d3d3d;
  display: flex;
  align-items: center;

  .uiParagraphLeft {
    height: 22px;
    color: var(--grey-color);
    display: flex;
    align-items: center;
  }

  .uiParagraphRight {
    display: flex;
    height: 22px;
    align-items: center;
    justify-content: flex-end;
    color: var(--grey-color);

    i {
      margin-left: 0.5rem;
    }

    .uiAddCheckbox {
      background-color: transparent;
      border: none;
      display: flex;
      align-items: center;
      color: var(--grey-color);
      box-shadow: none !important;
      padding: 0;

      span {
        color: var(--neutral-color);
      }
    }

    .uiAddCheckbox:hover {
      .uiPlusCircleIcon {
        background-image: url("./assets/icons/plus-circle-hover.svg");
      }
    }
  }
}

.uiEditPrivacyFieldsRow {
  background-color: var(--lightgrey-color);
  padding: 1rem 1rem;
  margin: 0 !important;
  font-size: 12px;

  .uiHeadInput {
    width: 100%;
    background-color: transparent;
    border: none;
    box-shadow: none;
  }

  .uiDateInput {
    width: 100%;
  }

  .uiEditPrivacyFieldsFlex {
    display: flex;
    flex-direction: row;

    /*SELECT */
    .uiHeadSelect {
      width: 100%;
      box-shadow: none;
      color: var(--neutral-color);
      font-size: 12px;

      nz-select-top-control {
        background-color: transparent;
        box-shadow: none !important;
        border: none;

        nz-select-item {
          background-color: #5d5d5d;
          border: none;
          color: var(--neutral-color);

          .ant-select-selection-item-remove {
            padding-left: 1rem;
            color: var(--neutral-color);
          }
        }
      }
    }

    .uiNoCancel {
      nz-select-top-control {
        nz-select-item {
          .ant-select-selection-item-remove {
            display: none;
          }
        }
      }
    }

    nz-upload {
      width: 100%;

      .ant-upload {
        width: 100%;
      }

      .uiUploadBtn {
        box-shadow: none;
        background-color: transparent;
        border: none;
        width: 100%;
        padding: 0;
      }

      nz-upload-list {
        display: none;
      }
    }

    .uiGreyCol {
      background-color: var(--grey-color);
      color: var(--neutral-color);
      padding: 0.5rem;
      height: 55px;
      display: flex;
      align-items: center;
      width: 100%;
      max-width: 130px;

      p {
        margin: 0;
        line-height: 1;
      }
    }

    .uiBlackCol {
      background-color: var(--semidark-color);
      color: var(--neutral-color);
      padding: 0.5rem;
      border: 1px solid var(--grey-color);
      height: 55px;
      display: flex;
      align-items: center;
      width: 100%;

      p {
        margin: 0;
        line-height: 1;
      }

      font-size: 12px;
    }

    .uiLightGreyCol {
      background-color: #e6e6e6;
      color: var(--dark-color);
      padding: 0.5rem 0.5rem 0.5rem 0.5rem;
      border: 1px solid var(--grey-color);
      height: 55px;
      display: flex;
      align-items: center;
      width: 100%;
    }

    .uiGreenCol {
      background-color: #e7ffcd;
      color: var(--neutral-color);
      padding: 0.5rem;
      font-size: 12px;
      border: 1px solid var(--grey-color);
      height: 55px;
      display: flex;
      align-items: center;
      width: 100%;

      p {
        margin: 0;
        line-height: 1;
      }
    }

    .uiYellowCol {
      background-color: #fff2cc;
      color: var(--grey-color);
      padding: 0.5rem 0 0.5rem 0.5rem;
      border: 1px solid var(--grey-color);
      height: 55px;
      display: flex;
      align-items: center;
      width: 100%;

      .uiUploadBtn {
        box-shadow: none;
        background-color: transparent;
        border: none;
        width: 100%;
        padding: 0;
      }
    }
  }
}

.uiCheckboxTableContainer {
  margin: 1rem;
  height: calc(100% - 400px);

  .uiBkgContainer {
    height: 100%;
    background-color: var(--semidark-color);
  }

  .uiCheckboxTable {
    color: var(--neutral-color);
    height: 100%;
    border: 1px solid #9d9d9c;

    thead {
      tr {
        th {
          background-color: var(--grey-color);
          color: var(--neutral-color);
          padding: 0 0.5rem;
          border: 1px solid #808080;
        }
      }
    }

    tbody {
      .uiDarkColorRow {
        background-color: #4d4d4d;
      }

      .uiLightColorRow {
        background-color: #5e5e5e;
      }

      tr {
        color: var(--neutral-color);

        td {
          padding: 0.5rem 1rem;
          border: 1px solid #a1a1a0;

          .uiTableInput {
            background-color: transparent !important;
            border: none;
            box-shadow: none;
            color: var(--neutral-color);
          }

          .uiTableSelect {
            width: 100%;
            box-shadow: none;
            color: var(--neutral-color);

            nz-select-top-control {
              background-color: transparent;
              box-shadow: none !important;
              border: none;

              nz-select-item {
                background-color: transparent;
                border: none;
                color: var(--neutral-color);

                .ant-select-selection-item-remove {
                  padding-left: 1rem;
                  color: var(--neutral-color);
                }
              }
            }

            nz-select-arrow {
              color: var(--neutral-color);
            }
          }

          nz-switch {
            .ant-switch {
              border: 1px solid var(--neutral-color);
            }

            .ant-switch-handle::before {
              background-color: var(--neutral-color);
            }
          }
        }

        .uiEditedCol {
          color: var(--neutral-color);
        }

        .uiGreenCol {
          background-color: #e7ffcd;
        }

        .uiOptionCol {
          padding-top: 0;
          padding-bottom: 0;

          .uiBorderCol {
            border-right: 1px solid var(--neutral-color);
          }
        }

        .uiNoEditedCol {
          background-color: #2b2c2c;
        }
      }

      nz-embed-empty {
        background-color: #4d4d4d;

        nz-empty {
          color: var(--neutral-color);
        }
      }

      .uiDarkColorRow:hover .uiEditedCol {
        background-color: #4d4d4d !important;
      }

      .uiLightColorRow:hover .uiEditedCol {
        background-color: #5e5e5e !important;
      }

      .uiGreenCol {
        background-color: #e7ffcd;
      }

      // tr:hover .uiEditedCol {
      //     background-color: #2b2c2c !important;
      // }
      // tr:hover .uiNoEditedCol {
      //     background-color: #2b2c2c !important;
      // }
      .ant-table-placeholder {
        td {
          border: none !important;
        }

        .ant-table-expanded-row-fixed {
          width: auto;
        }

        pointer-events: none;
        background-color: var(--darkgrey-color);

        nz-embed-empty {
          background-color: #4d4d4d;

          nz-empty {
            color: var(--neutral-color);
          }
        }
      }
    }
  }
}

/*COMPILAZIONE ROOT */
.uiContainer {
  display: flex;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .uiCompilazioneInnerLayout {
    min-height: calc(100vh - 37px);

    .uiCompilazioneContent {
      background-color: #d6d6d6 !important;

      .uiCompilazioneInnerContent {
        height: 100%;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .uiCompilazioneInnerLayout {
      min-height: calc(100vh - 64px);
    }

    .uiCompilazioneQuestionsFooter {
      padding: 24px 24px;
    }
  }
}

/*COMPILAZIONE HOMEPAGE */
.uiCompilazioneHomeContainer {
  background-color: var(--dark-color);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  img {
    margin-top: 4rem;
    margin-bottom: 2rem;
    max-width: 80%;
  }

  .uiDescriptionText {
    margin: 2rem;
    color: var(--neutral-color);
  }

}

/* COMPILAZIONE INTESTAZIONE */
.uiCompilazioneHeadContainer {
  background-color: var(--dark-color);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .uiHeadInnerLayout {
    width: 100%;

    .uiCompilazioneHeadHeader {
      position: -webkit-sticky;
      /* Safari */
      position: sticky;

      z-index: 2;
      top: 0;
      background-color: var(--primary-color);
      display: flex;
      align-items: center;
      justify-content: space-between;

      .uiHeaderCustLogo {
        max-height: 70%;
        width: auto;
        max-width: 200px;
      }

      .uiHeaderLogo {
        max-height: 50%;
        max-width: 30%;
      }

      .uiQuestTitle {
        font-size: 20px;
        line-height: 20px;
        color: var(--neutral-color);
        padding-top: 5px;

        .uiSubtitleMain {
          font-size: 10px;
        }
      }
    }
  }

  .uiCompilazioneHeadContent {
    background-color: var(--dark-color);
    width: 100%;
    height: 100%;
    padding: 2rem;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    h1 {
      color: var(--neutral-color);
      font-size: 30px;
      font-weight: 800;
    }

    .uiCompilazioneHeadForm {
      width: 100%;
      margin-top: 2rem;
      max-width: 800px;

      .uiPrivacyFormRow {
        margin-bottom: 2rem;
      }

      .uiPrivacyHeadRow {
        color: var(--neutral-color);
        margin-top: 2rem;

        p {
          text-align: center;
        }

        .uiPrivacyTitle {
          font-size: 20px;
          font-weight: bold;
        }

        .uiAttachLink {
          color: var(--grey-color);
          text-decoration: underline;
        }
      }

      .uiErrorContainer {
        width: 100%;
      }

      .uiFormRow {
        .uiFormInput {
          background-color: #e6e6e6;
          color: var(--dark-color);
          width: 100%;
          padding: 10px;
          margin-top: 1rem;
        }

        .uiFormInput::placeholder,
        .uiFormInputNumber input::placeholder {
          color: var(--grey-color);
        }

        .uiFormInputNumber {
          background-color: #e6e6e6;
          color: var(--dark-color);
          padding: 6px 10px;
          margin-top: 1rem;
        }

        .uiFormRadioContainer {
          color: var(--neutral-color);
          margin-top: 1rem;
          display: flex;
          flex-direction: row;

          .uiFormRadio {
            margin-left: 1rem;

            label {
              color: var(--neutral-color);
            }
          }
        }

        .uiFormPrivacyContainer {
          width: 100%;
          color: var(--neutral-color);

          p {
            margin: 0;
          }

          .uiFormRadio {
            label {
              color: var(--neutral-color);
            }
          }
        }

        .uiFormNote {
          margin-top: 1rem;
          color: var(--neutral-color);

          p {
            margin: 0;
          }
        }

        .uiFormSwitchContainer {
          color: var(--neutral-color);
          margin-top: 1rem;

          .uiFormLabel {
            margin-right: 1rem;
          }

          nz-switch {
            margin: 0 5px;

            .ant-switch {
              border: 1px solid var(--grey-color);
            }

            .ant-switch-handle::before {
              background-color: var(--grey-color);
            }
          }
        }

        .uiFormSubmit {
          margin-top: 2rem;
          width: 100%;
          max-width: 500px;

          .uiHeadFormSubmit {
            background-color: var(--primary-color);
            border: 1px solid var(--grey-color);
            padding: 0 2rem;
            color: var(--neutral-color);
            border-radius: 5px;
            height: 50px;
            width: 100%;
          }

          .uiHeadFormSubmit:hover,
          .uiHeadFormSubmit:active {
            background-color: var(--neutral-color);
            border: 1px solid var(--secondary-color);
            color: var(--secondary-color);
          }
        }
      }
    }
  }
}

/* COMPILAZIONE DOMANDE */
.uiCompilazioneQuestionsContainer {
  background-color: var(--dark-color);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .uiQuestionsInnerLayout {
    width: 100%;

    position: absolute;
    height: 100%;
    top: 0;
    left: 0;

    .ant-radio {
      top: 0em;
    }

    .uiCompilazioneQuestionsHeader {
      background-color: var(--primary-color);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;

      .uiHeaderCustLogo {
        max-height: 70%;
        width: auto;
        max-width: 200px;
      }

      .uiHeaderLogo {
        max-height: 50%;
        max-width: 30%;
      }

      .uiQuestTitle {
        font-size: 20px;
        line-height: 20px;
        color: var(--neutral-color);
        padding-top: 5px;


        .uiSubtitleMain {
          font-size: 10px;
        }
      }
    }

    .uiCompilazioneQuestionsContent {
      padding: 0 2rem;
      background-color: #3d3d3d !important;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      z-index: 1;

      .uiQuestionTitle {
        color: var(--neutral-color);
        font-size: clamp(16px, 3vw, 28px);
        font-weight: 800;
        margin-bottom: 30px;
        margin-top: 30px;
      }

      .uiAnswersRadio {
        label {
          display: flex;
          align-items: center;
          margin-bottom: 1rem;
          color: var(--neutral-color);
          font-size: clamp(16px, 2vw, 20px);
          padding: 10px;
          border: 1px solid;
        }

        label:active {
          background-color: var(--primary-color);
          color: var(--neutral-color);
        }

        .uiAnswerTextarea {
          width: 80vw;
          background: transparent;
          color: #fff;
        }

        .uiAnswerBtn {
          float: right;
          margin-top: 1rem;
          background-color: var(--primary-color);
          border: 1px solid var(--semidark-color);
          color: var(--neutral-color);
          border-radius: 7px;
        }

        .uiAnswerBtn:disabled {
          background-color: #eee;
          color: var(--dark-color);
          opacity: 0.8;
        }
      }
    }

    .uiSecondContent {
      position: absolute;
      height: calc(100vh - 80px - 64px - 37px);
      width: 100%;
      z-index: 0;
      top: 64px;
      background-color: #3d3d3d !important;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      pointer-events: none;
      // ###################### AMAJOR ANDI PATCH
      display: none !important;
      // ######################

      .uiQuestionTitle {
        color: var(--neutral-color);
        font-size: 30px;
        font-weight: 800;
        margin-bottom: 5rem;
      }

      .uiAnswersRadio {
        label {
          display: block;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 1rem;
          color: var(--neutral-color);
          font-size: 20px;
        }

        .uiAnswerTextarea {
          width: 80vw;
        }
      }
    }

    @media screen and (max-width: 576px) {
      .uiSecondContent {
        height: calc(100vh - 80px - 64px - 65px);
      }
    }

    .uiCompilazioneQuestionsFooter {
      background-color: var(--semidark-color);
      //height: 116px;
      // display: flex;
      // flex-direction: column;
      // justify-content: center;
      padding: 0;

      .uiBackIcon {
        font-size: 2rem;
        color: var(--secondary-color);
        cursor: pointer;
        float: left;
        padding: 10px;
        background-color: var(--primary-color);
        color: white;
        margin: 0;
      }

      .uiCenterText {
        color: var(--neutral-color);
        font-size: 30px;
        line-height: 35px;
        font-weight: 800;
        text-align: center;
      }

      .uiForwardIcon {
        font-size: 2rem;
        cursor: pointer;
        float: right;
        padding: 10px;
        background-color: var(--primary-color);
        color: white;
        margin: 0;
      }

      .uiCompilazioneProgress {
        width: 100%;
        position: absolute;
        top: 70px;
        left: 0;
        right: 0;
        padding: 0 50px;
        z-index: 10;

        .ant-progress-bg {
          background-color: var(--secondary-color);
        }

        .ant-progress-inner {
          background-color: #bbb;
        }
      }

      // ###############################
      .questFooter {
        height: 38px !important;
        font-size: 11px;
        flex: 0 0;
        padding: 10px 24px;
        background-color: var(--dark-color);
        color: var(--neutral-color);
        width: 100vw;
        z-index: 1000;

        .ocalab {
          color: var(--neutral-color);
        }

        .ocalab:hover {
          color: #fdc300;
        }

        i {
          margin-left: 10px;
          margin-right: 5px;
        }

        .footerLink {
          color: var(--neutral-color);
          text-decoration-color: var(--neutral-color);
          text-decoration: underline;
        }

        .footerRight {
          text-align: right;
        }
      }

      /* MOBILE COMPILAZIONE QUESTIONARIO */
      @media screen and (max-width: 767px) {
        .questFooter {
          height: 64px !important;
          text-align: center !important;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .footerRight {
            text-align: center;
          }
        }

        .uiQuestTitle {
          display: none;
        }

        .uiCompilazioneHeadContent {
          margin-bottom: 64px !important;

          .uiQuestMobileTitle {
            display: block !important;
            font-size: 16px;
            color: var(--neutral-color);
            margin-bottom: 1rem;
            text-align: center;
          }

          .uiFormRadioContainer .uiFormRadio {
            display: block;
          }
        }
      }

      @media screen and (max-width: 300px) {
        .questFooter {
          font-size: 9px;
        }
      }
    }
  }
}

/* COMPILAZIONE CONCLUSIONE */
.uiCompilazioneEndContainer {
  background-color: var(--dark-color);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .uiEndInnerLayout {
    width: 100%;

    .uiCompilazioneEndHeader {
      background-color: var(--primary-color);
      display: flex;
      align-items: center;
      justify-content: space-between;

      .uiHeaderCustLogo {
        height: 56px;
        width: auto;
        max-width: 200px;
      }

      .uiHeaderLogo {
        max-height: 50%;
        max-width: 30%;
      }

      .uiQuestTitle {
        font-size: 20px;
        line-height: 20px;
        color: var(--neutral-color);
        padding-top: 5px;

        .uiSubtitleMain {
          font-size: 10px;
        }
      }
    }

    @media screen and (max-width: 576px) {
      .uiCompilazioneEndHeader {
        justify-content: center;
      }
    }

    .uiCompilazioneEndContent {
      color: white;
    }
  }

  .uiCompilazioneEndContent {
    background-color: var(--dark-color);
    width: 100%;
    height: 100%;
    padding: 0 2rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    h1 {
      color: var(--neutral-color);
      font-size: 30px;
      font-weight: 800;
    }

    h3 {
      color: var(--neutral-color);
      font-size: 20px;
      font-weight: 500;
    }

    .uiEndButton {
      margin-top: 1rem;
      background-color: var(--primary-color);
      border: 1px solid var(--grey-color);
      padding: 0 2rem;
      color: var(--neutral-color);
      border-radius: 5px;
      height: 50px;
    }

    .uiEndButton:hover,
    .uiEndButton:active {
      background-color: var(--neutral-color);
      border: 1px solid var(--primary-color);
      color: var(--primary-color);
    }
  }

  @media screen and (max-width: 576px) {
    .uiCompilazioneEndContent {
      text-align: center;
    }
  }
}

//GESTIONE CLIENTE
.uiClienteHeader {
  padding: 0 24px;
  background-color: var(--semidark-color);
  color: var(--neutral-color);

  .uiCienteHeaderRow {
    height: 100%;

    .uiClienteHeaderLeftCol {
      height: 100%;
      display: flex;
      align-items: center;

      .uiClienteLogo {
        height: 50px;
        display: inline-block;
        vertical-align: middle;
      }
    }

    .uiClienteHeaderRightCol {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 100%;
    }
  }
}

.uiClienteSidebar {
  position: relative;
  z-index: 10;
  min-height: calc(100vh - 37px);
  background: var(--semidark-color);

  .uiClienteSidebarLogo {
    height: 64px;
    padding: 0 24px;
    background-color: var(--semidark-color);
    color: var(--neutral-color);
    display: flex;
    justify-content: center;
    align-items: center;

    .uiClienteLogo {
      height: 50px;
      display: inline-block;
      vertical-align: middle;
    }
  }

  .uiClienteMenu {
    background-color: transparent;
    border-right: none;

    .uiClienteMenuItem {
      background-color: transparent;

      a {
        background-color: transparent;
        color: var(--grey-color);
        display: flex;
        align-items: center;
      }
    }

    .uiClienteMenuItem:hover,
    .uiClienteMenuItem:active,
    .uiClienteMenuItem:focus {
      background-color: var(--primary-color);
      color: var(--neutral-color);
      border: 1px solid var(--neutral-color);

      a {
        color: var(--neutral-color);
      }
    }

    .uiClienteMenuItem.ant-menu-item-selected {
      background-color: var(--primary-color);
      border: 1px solid var(--neutral-color);

      a {
        color: var(--neutral-color) !important;

        .uiDestinatariIcon {
          background-image: url("./assets/icons/destinatari-hover.svg");
        }

        .uiAzienda{
          background-image: url("./assets/icons/utenti-gruppi-hover.svg"); 
        }
        .uiAggregatiIcon {
          background-image: url("./assets/icons/aggregati-hover.svg");
        }

        .uiQuestIcon {
          background-image: url("./assets/icons/quest-hover.svg");
        }
      }
    }

    .uiClienteMenuItem.ant-menu-item-disabled {
      a {
        background-color: transparent;
        color: var(--grey-color) !important;
        display: flex;
        align-items: center;
        opacity: 0.3;
      }
    }

    .ant-menu-item-selected::after {
      display: none;
    }
  }
}

.uiAdminClientiContent {
  background-color: #4d4d4d !important;
  display: flex;
  width: 100%;
  flex-direction: column;

  .uiAdminClientiHeader {
    height: 5%;
    display: flex;
    justify-content: space-between;
    margin: 1rem 1rem 0 1rem;

    .uiAdminClientiLeftHeader {
      width: 100%;

      .uiSerchbar,
      .uiSerchbar>input {
        height: 100%;
        background-color: transparent;
        color: var(--neutral-color);
      }
    }
  }

  .uiAdminClientiTableContainer {
    margin: 1rem;
    height: calc(95% - 3rem);

    .uiAdminClientiTable {
      height: 100%;
      background-color: var(--semidark-color);
      border: 1px solid #7d7d7d;

      thead {
        tr {
          th {
            background-color: var(--grey-color);
            color: var(--neutral-color);
            padding: 0.3rem 0;
            font-size: 12px;
            line-height: 1rem;
            border: 1px solid #808080;
          }
        }
      }

      tbody {
        .uiDarkColorRow {
          background-color: #d6d6d6;
          color: var(--dark-color);
          height: 45px;

          td {
            background-color: #d6d6d6;
            color: var(--dark-color);
            padding: 0.5rem 1rem;
            border: 1px solid #a1a1a0;
          }
        }

        .uiLightColorRow {
          background-color: #e6e6e6;
          color: var(--dark-color);

          td {
            background-color: #e6e6e6;
            color: var(--dark-color);
            padding: 0.5rem 1rem;
            border: 1px solid #a1a1a0;
          }
        }

        .uiBlackCol {
          background-color: var(--semidark-color) !important;
          padding: 0;
        }

        .uiGreenCol {
          background-color: #e7ffcd !important;
        }

        .uiOnCol {
          background-color: #e7ffcd !important;
        }

        .uiOffCol {
          background-color: #ffe6cb !important;
        }

        .uiYellowCol {
          background-color: #fff2cc !important;
        }

        .uiGreyCol {
          background-color: #4d4d4d !important;
          color: var(--neutral-color) !important;
          padding: 0.5rem;
        }

        .ant-table-placeholder {
          td {
            border: none !important;
          }

          .ant-table-expanded-row-fixed {
            width: auto !important;
          }

          background-color: var(--darkgrey-color);
          pointer-events: none;

          nz-embed-empty {
            background-color: #4d4d4d;

            nz-empty {
              color: var(--neutral-color);
            }
          }
        }

        tr {
          td {
            nz-switch {
              .ant-switch {
                border: 1px solid var(--neutral-color);
              }

              .ant-switch-handle::before {
                background-color: var(--neutral-color);
              }

              .ant-switch-checked.ant-switch-disabled {
                opacity: 1;
              }
            }

            .uiFilterSelect {
              width: 100%;
              background: none;
              border: none;

              nz-select-item {
                background: none;
                border: none;

                .ant-select-selection-item-remove {
                  display: none;
                }
              }
            }

            nz-upload {
              width: 100%;

              .ant-upload {
                width: 100%;
              }

              .uiUploadBtn {
                box-shadow: none;
                background-color: transparent;
                border: none;
                width: 100%;
                padding: 0;
              }

              nz-upload-list {
                display: none;
              }
            }

            .uiUploadBtn {
              box-shadow: none;
              background-color: transparent;
              border: none;
              width: 100%;
              padding: 0;
            }
          }
        }

        tr:hover td {
          background-color: rgba(157, 157, 156, 0.3);
        }
      }
    }
  }
}

.innerCommentModal {
  .ant-modal-content {
    background-color: #d6d6d6 !important;
  }

  .ant-modal-footer {
    display: none;
  }
}

.chartOverlay {
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.8;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h1 {
    color: #fff;
    opacity: 1;
  }
}

.ant-table table {
  overflow: hidden;
}

/* MOBILE COMPILAZIONE QUESTIONARIO */
@media screen and (max-width: 767px) {
  .uiFooter {
    height: 64px !important;
    text-align: center !important;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .footerRight {
      text-align: center;
    }
  }

  .uiQuestTitle {
    display: none;
  }

  .uiCompilazioneHeadContent {
    margin-bottom: 64px !important;

    .uiQuestMobileTitle {
      display: block !important;
      font-size: 16px;
      color: var(--neutral-color);
      margin-bottom: 1rem;
      text-align: center;
    }

    .uiFormRadioContainer .uiFormRadio {
      display: block;
    }
  }
}

@media screen and (max-width: 300px) {
  .uiFooter {
    font-size: 9px;
  }
}

.printChart {
  position: fixed;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100%;
  z-index: -1;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
}

.flex-cell-col {
  display: flex;
  flex-direction: column;
}

.icon-prefix {
  margin: 0 15px 0 5px;
}

.send-type {
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 2px solid #a1a1a0;

  .ant-switch {
    border: 2px solid #a1a1a0 !important;
    height: 24px;
  }

  .ant-switch-handle::before {
    background: var(--dark-color) !important;
  }

  .ant-switch-inner {
    color: var(--primary-color);
  }
}

.importTable {
  .uiOnCol {
    background-color: #e7ffcd !important;
  }

  .uiOffCol {
    background-color: #ffe6cb !important;
  }

  .ant-table.ant-table-small .ant-table-tbody>tr>td {
    padding: 4px;
    font-size: 11px;
  }

  .ant-table-expanded-row-fixed {
    width: unset !important;
  }
}


// ####################################################
// ############# spinner
.waiter {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba($color: #000000, $alpha: 0.6);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    width: 3em;
    height: 3em;

    i {
      width: 20px;
      height: 20px;
      background-color: #c8273d;
    }
  }
}


.ant-input {
  transition: .7s;

  &:focus,
  &:hover {
    border-color: #661615;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 15px 15px;
  }
}